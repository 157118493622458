
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We offer a comprehensive service in building surveying, including:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Ensure projects are completed on budget and to schedule`);elementClose('li');
elementOpen('li');text(`Advise clients on schemes and projects and determine requirements`);elementClose('li');
elementOpen('li');text(`Prepare scheme designs with costings, programmes for completion of projects and specification of works`);elementClose('li');
elementOpen('li');text(`Organise documents for tender and advise on appointing contractors, designers and procurement routes`);elementClose('li');
elementOpen('li');text(`Determine the condition of existing buildings, identify and analyse defects, including proposals for repair`);elementClose('li');
elementOpen('li');text(`Advise on energy efficiency, environmental impact and sustainable construction`);elementClose('li');
elementOpen('li');text(`Instruct on the preservation/conservation of historic buildings`);elementClose('li');
elementOpen('li');text(`Advise on the management and supervision of maintenance of buildings`);elementClose('li');
elementOpen('li');text(`Deal with planning applications and advise on property legislation and building regulations`);elementClose('li');
elementOpen('li');text(`Assess and design buildings to meet the needs of people with disabilities`);elementClose('li');
elementOpen('li');text(`Instruct on construction design and management regulations`);elementClose('li');
elementOpen('li');text(`Negotiate dilapidations (when there is a legal liability for a property's state of disrepair)`);elementClose('li');
elementOpen('li');text(`Carry out feasibility studies`);elementClose('li');
elementOpen('li');text(`Advise on the health and safety aspects of buildings`);elementClose('li');
elementOpen('li');text(`Advise on boundary and 'right to light' disputes and party wall procedures`);elementClose('li');
elementOpen('li');text(`Prepare insurance assessments and claims.`);elementClose('li');
elementClose('ul');
elementClose('div');
  elementClose('div');
  return el;
};