
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We can help you to assess whether your cladding is compliant with statutory requirements and advise you on high quality, practical and cost-effective repairs or remediation. We tailor our approach to suit our client’s budget, appetite for risk and other factors. This begins with listening, absorbing and appraising ourselves of all the matters which should shape the best strategy for the client.')
elementClose('p');
elementOpen('p');
text('Procom has been providing first-class Construction consultancy services since 2009. Having expanded significantly, our team provides a solid foundation of industry-leading expertise and knowledge, earning recognition for its professional approach. We have extensive experience and a proven track record of delivering results, having worked with many leading edge clients, such as BAA, Transport for London, Yorkshire Water, MoD and many of the world’s leading construction companies.')
elementClose('p');
elementOpen('p');
text('Procom is committed to providing its clients outstanding quality services, secured by a combination of qualifications, expertise and experience. Procom will manage the entire process from investigation, solution design, tendering, implementation, quality control through to the issuing of ESW1 forms on completion.')
elementClose('p');
elementOpen('p');
text('All of our professional staff are degree qualified and hold relevant memberships of professional bodies related to their field of expertise, such as RICS, MCIOB, MAPM. Our skilled team services clients nationally and internationally and comprises quantity surveyors / cost consultants, project managers, Forensic Cost Auditors and Principal Designers.')
elementClose('p');
elementOpen('p');
text('Our cladding remediation solution includes:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Reporting on Cladding materials and systems currently in place`);elementClose('li');
elementOpen('li');text(`Proposal of options for a compliant cladding remediation solution`);elementClose('li');
elementOpen('li');text(`Understanding, developing and communicating the project need from stakeholders`);elementClose('li');
elementOpen('li');text(`Establishing output criteria and performance – scope, time, quality and cost`);elementClose('li');
elementOpen('li');text(`Design & Specification of Cladding Replacement`);elementClose('li');
elementOpen('li');text(`Building Regulation & Planning Approvals submission and management`);elementClose('li');
elementOpen('li');text(`Procurement Strategy Advice & Implementation, including support for funding`);elementClose('li');
elementOpen('li');text(`Planning the project pathway, establishing inputs to achieve the desired outcomes`);elementClose('li');
elementOpen('li');text(`Construction contract selection advice`);elementClose('li');
elementOpen('li');text(`Contract conditions; negotiation and agreement; contract price negotiation`);elementClose('li');
elementOpen('li');text(`Early identification and management of risks to the performance of the contract. The management of potential disputes to avoid impact to time, cost and quality.`);elementClose('li');
elementOpen('li');text(`Contract preparation; engrossments and execution`);elementClose('li');
elementOpen('li');text(`Client budget and spend management, reporting and forecasting`);elementClose('li');
elementOpen('li');text(`Seeing that the other party / parties to the contract perform properly as the contract stipulates; on time and to the requisite standards`);elementClose('li');
elementOpen('li');text(`Prompt and effective resolution of non-compliance with the contract / contract breach utilising available remedies`);elementClose('li');
elementOpen('li');text(`Production of EWS1 Forms on completion of the Works`);elementClose('li');
elementClose('ul');
elementClose('div');
  elementClose('div');
  return el;
};