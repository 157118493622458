import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import { newsData } from './newsdata';
import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

import procomLogo from '../../procomlogo24.svg';

import './news.scss';

export const excomNewsPix = () => {
  <render>
    <div id="pe-banner-photo">
      <picture>
        <source type="image/png" srcset="/images/procom-excom-photo-400.png 400w,/images/procom-excom-photo-1024.png 1024w,/images/procom-excom-photo-1920.png 1920w" sizes="100vw"></source>
        <img src='/images/procom-excom-photo-400.png'></img>
      </picture>
    </div>
  </render>
}

export const excomNews = () => {
  <render>
    <div id='procom-excom-wrapper'>
      <div id='pe-header'>
        <div class='pe-header-logos'>
          <div class='excom-logo'>
            { () => sitePix(SiteData.assetBase, 'site/excom-logo') }
          </div>

          <p class='pe-logos-join'>JOINS HANDS WITH</p>
          { () => procomLogo('procom-logo') }
        </div>
        <div class='pe-header-text'>
<p>We are delighted to announce that EXCOM has joined hands with Procom as a part of the collaborative strategy to optimise the presence in KSA construction market.</p>
<p>Procom is a UK based cost consultancy firm established in 2009 and has provided a solid foundation of industry-leading expertise and knowledge for the clients, earning recognition for its professional approach. EXCOM has been working with Procom since 2019 by providing pre-contract cost consultancy services.</p>
<p>Colin Hardiman (Managing Director - Procom), Mark Sanchez (Director - Procom), Uthpala Jayaweera (CEO - EXCOM), Duminda Dissanayaka (Managing Director - EXCOM) and Janaka Nalin (Country Manager KSA - EXCOM) met in Riyadh in last week to inaugurate the joint operations in KSA.</p>
<p>With this milestone, both Procom and EXCOM aim to broaden the horizons of commercial management in the ever-evolving construction arena to provide a superior service to potential clients.</p>
        </div>
      </div>
      { () => sitePix(SiteData.assetBase, 'site/procom-excom-photo') }
    </div>
  </render>
}

export const newsPix = (newsImage) => {
    if (typeof newsImage === 'string') {
      if (newsImage[0] === '/') {
        <render>
          <div class='sitepix-wrapper'>
            <img src={newsImage} />
          </div>
        </render>
        console.log("public image");
      } else {
        sitePix(SiteData.assetBase, newsImage);
      }

      return
    }
    if ( newsImage === null) {

        return
    }
    if (Array.isArray(newsImage)) {
        // TODO: Show slideshow if there are multiple images
        sitePix(SiteData.assetBase, newsImage[0]);
        return;
    }
    console.log("Unknown news image type");
}

export const newsPage = (pco) => {
  <render>
      <h2>News</h2>
      {/* { () => excomNews() } */}
      {() => newsData.map(newsItem => {
          <render>
              <div class="news-row">
                  <h2 class='news-row__title'>{newsItem.title}</h2>
                  <p class='news-row__date'>{newsItem.date}</p>
                  <div class="news-row__content-wrapper">
                      { () => newsPix(newsItem.image) }
                      {() => {
                          if (typeof newsItem.body === 'function') {
                              <render>
                                  <div class='news-content'>
                                  { newsItem.body }
                                  </div>
                              </render>
                          } else if (typeof newsItem.body === 'string') {
                              if (newsItem.body[0] === '<') {
                                  <render><div class='news-content' dangerouslySetInnerHTML={newsItem.body}></div></render>
                              } else {
                                  const contentlines = newsItem.body.replace(/\r\n|\n\r|\n|\r/g,"\n").split("\n");
                                  <render>
                                      <div class='news-content'>
                                          { () => contentlines
                                              .map(l => l.trim())
                                              .filter(l => l.length > 0)
                                              .map(l => <render><p>{l}</p></render>) }
                                      </div>
                                  </render>
                              }

                          }
                      }}
                  </div>
              </div>
          </render>
      })}
  </render>
}

