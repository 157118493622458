import { PageContent } from '@appzuka/rxnano';

import { JSXFactory } from '@appzuka/rxnano';
import { SectorBanner, bannerTitle } from 'site/components/sectorBanner';

import { linkClick } from '@appzuka/rxnano';
import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

import { SnrCostConsultant } from './snrcostconsultant';
import { QuantitySurveyor } from './quantitysurveyor';

import './joinus.scss';


const joinusContent = () => {
  <render>
    <h2>Our Mission</h2>
    <p>To deliver effective management and best value estate and construction solutions always with the aim of exceeding our client’s expectations and upholding our commitment to fairness, honesty and continuous improvement.</p>
    <h2>About Procom</h2>
    <p>Specialising in construction procurement, commercial and project management and forensic cost auditing Procom has earned recognition for its professional approach serving all clients with a good quality service secured by a combination of qualifications, expertise and experience.</p>
    <p>We work in both the public and private sector and for all contracting parties.</p>
    <p>Our approach to business is simple. We are:</p>
    <ul>
      <li>Open</li>
      <li>Honest</li>
      <li>Reliable</li>
      <li>Service focused</li>
      <li>Results driven</li>
    </ul>

    <p>Our philosophy is to build upon our areas of expertise, forming strong relationships with clients and providing the very best teams to provide surety of delivery.</p>
    <p>We have extensive experience of traditional contracts and partnering arrangements including open book contracts, in particular NEC3, PPC 2000 and TPC 2005.</p>
    {ListJobs}
  </render>

}

const jobs = [
  {
    name: 'snrcostconsultant',
    title: 'Senior Cost Consultant',
    content: SnrCostConsultant
  },
  {
    name: 'quantitysurveyor',
    title: 'Quantity Surveyor (Bill preparation expertise)',
    content: QuantitySurveyor
  }
]

const JoinAbout = () => {
  <render>
    <div>
      <h2>About Us</h2>
      <p>Procom is a well-established and leading Berkshire based consultancy providing quantity surveying, project management, forensic cost auditing and CDM Co-ordinator services.</p>
      <p>As part of our planned expansion, we are looking for ambitious and forward thinking Quantity Surveying and project manager graduates to join our head office based team in Wokingham.</p>

      <h2>Our Team</h2>

      <p>The Procom team is comprised of varied professionals including:</p>

      <ul>
        <li>Quantity Surveyors      </li>
        <li>Cost Consultants        </li>
        <li>Project Managers        </li>
        <li>Forensic Cost Auditors  </li>
        <li>Principal Designers     </li>
      </ul>
    </div>
  </render>
}

const ListJobs = () => {
  <render>
    <h2>Current Vacancies</h2>
    <p>Clink on a link below to learn more about current vacancies at Procom.</p>
    <ul>
      {() => jobs.map(j => {
        <render>
          <li class='job-list'><a href={`/about/people/join/${j.name}`} onclick={e => linkClick(e, `/about/people/join/${j.name}`)}>
            {j.title}
          </a></li>
        </render>
      })}
    </ul>
  </render>
}

const jobContent = (_, { job }) => {
  const thisJob = jobs.find(j => j.name === job);
  if (!thisJob) {
    <render>
      <div class='job-wrapper'>
        <h2>Position: {job} not found.</h2>
        {ListJobs}
      </div>
    </render>
    return;
  }
  <render>
    <div class='job-wrapper'>
      <a href={`/about/people/join`} onclick={e => linkClick(e, `/about/people/join`)} class='job-wrapper__back'>&lt; Back to all vacancies</a>
      {thisJob.content}
      {JoinAbout}
      {ListJobs}
    </div>
  </render>
}

let pageList = [
  {
    pageLocation: 'about/people/join',
    // image: [`banners/${banner}`, 'Services'] as [string, string],
    image: () => SectorBanner(`banners/joinus`, () => bannerTitle('Join Us', null), { link: '/about', label: 'About Procom' }, { link: '/about/people', label: `Back: People` }),
    pageTitle: 'Join Us',
    content: joinusContent,
    name: name
  },
  {
    pageLocation: 'about/people/join/:job',
    // image: [`banners/${banner}`, 'Services'] as [string, string],
    image: (pco, { job }) => SectorBanner(`banners/joinus`, () => {
      const thisJob = jobs.find(j => (j.name === job));
      const jobTitle = thisJob ? `Join Us: ${thisJob.title}` : 'Join Us';
      bannerTitle(jobTitle, null);
    }, { link: '/', label: 'About Procom' }, { link: '/about/people/join', label: `Back: Join` }),
    pageTitle: 'Join Us',
    content: jobContent,
    name: name
  }
];


const joinusPages: PageContent = {
  content: pageList,
  subMenu: []
}

export {
  joinusPages
};

