
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Our team\'s skills include:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Understanding, developing and communicating the project need from stakeholders`);elementClose('li');
elementOpen('li');text(`Establishing output criteria and performance – scope, time, quality and cost`);elementClose('li');
elementOpen('li');text(`Planning the project pathway, establishing inputs to achieve the desired outcomes`);elementClose('li');
elementOpen('li');text(`Assembling competent project teams`);elementClose('li');
elementOpen('li');text(`Allocating, co-ordinating and managing the activities of the project team to deliver desired outputs / outcomes`);elementClose('li');
elementOpen('li');text(`Managing change to the project pathway`);elementClose('li');
elementOpen('li');text(`Monitoring the quality of project inputs and outputs and assessing these against the established input and output performance criteria`);elementClose('li');
elementOpen('li');text(`Most importantly, our overarching focus is upon delivering our clients' projects on time, on budget, safely and to the prescribed standards.`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('We are also able to offer clients a project recovery service, if they are in the unfortunate position that an existing project has drifted off course. Our project managers are skilled trouble-shooters, who will offer the best chance of bringing wayward projects back on track.')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};