import { JSXFactory, linkClick, render } from '@appzuka/rxnano';
import { peopleData } from './peopledata';
import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

import './people.scss';

const setActive = (event) => {
  const els = document.getElementsByClassName('person-wrapper');
  Array.from(els).map(el => el.className = 'person-wrapper');
  // event.currentTarget.className = 'person-wrapper active';
}


export const peoplePage =
  <>
    <h2>People</h2>
    <p>All of our professional staff are degree qualified and hold relevant memberships of professional bodies related to their field of expertise, such as RICS, MCIOB, MAPM. Our skilled team services clients nationally and internationally and comprises quantity surveyors / cost consultants, project managers, Forensic Cost Auditors and Principal Designers.</p>
    <p><a href={`/about/people/join`} onclick={e => linkClick(e, `/about/people/join`)} class='site-link'>Click here to see a list of our current vacancies</a></p>
    <p>Please find below profiles of our Senior Leadership Team.</p>

    <div class='people-wrapper'>
      {() => {
        peopleData.map((p, i) => {
          render(
            <div class={`person-wrapper${i == -1 ? ' active' : ''}`} onclick={p.full.length > 0 ? (e) => setActive(e) : () => { }}>
              <div class='people-thumbnail'>
                {() => sitePix(SiteData.assetBase, `people/${p.image}`, {mediaSizes: '400px'})}
                <h2>{p.name}</h2>
                <p>{p.title}</p>
              </div>
              <div class='person-content'>
                <p>{p.full}</p>
              </div>
            </div>
          );
        })
      }}
    </div>
  </>

