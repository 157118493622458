import { JSXFactory } from '@appzuka/rxnano';
import { linkClick } from '@appzuka/rxnano';

export const SnrCostConsultant = (
  <div class='job-desc'>
    <div class="job-title-wrapper">
      <div class="job-row">
        <h2>Job Title</h2>
        <h2>Senior Cost Consultant</h2>
      </div>
      <div class="job-row">
        <h2>Role Type</h2>
        <h2>Permanant</h2>
      </div>
      <div class="job-row">
        <h2>Job Location</h2>
        <h2>Wokingham, Berkshire</h2>
      </div>
      <div class="job-row">
        <h2>Reports to</h2>
        <h2>Associate Director</h2>
      </div>
    </div>
    <h2>Requirement</h2>
    <p>Procom is progressing with a programme of significant growth combined with ongoing successful service delivery to our clients in the UK and abroad. As part of our growth programme, we require an experienced MRICS qualified Senior Cost Consultant to join our friendly, professional and highly successful team. You will need to have proven delivery experience, demonstrate that you work to a high standard. As Procom grows, you will have the opportunity to progress your career with us working across a wide range of sectors, projects and differing areas of professionalism that are covered within our service offering.</p>

    <h2>Role and Responsibilities</h2>
    <ul>
<li>
  Reporting to the Senior Leadership Team and taking responsibility for delivery of cost consultancy and/or Employers Agent commissions on a range of project types and complexity
  </li><li>Contributing to and supporting in the preparation and submission of bids for new commissions.
  </li><li>Promoting the Procom brand to both new and existing clients
  </li><li>Ensuing development and maintenance of strong relationships with clients and other stakeholders through ensuring outstanding service delivery
  </li><li>Carrying out duties efficiently and in accordance with business processes and procedures; 
  </li><li>Providing input into development of business processes and procedures
  </li><li>Taking personal responsibility for your compliance with quality, health, safety and environmental standards
  </li><li>Responsible for carrying out Senior Cost Consultant duties to a highly professional standard at both pre and post contract stages, including development of project control documentation, quality inspections, programme management, preparation of cost estimates, value engineering, procurement and tendering, contract preparation and administration, valuations and change management, final account, issuing of certificates in accordance with contract requirements
  </li><li>Preparation, delivery and presentation of professional reports to the clients, including project status reports, and providing professional advice, guidance and opinion
  </li><li>Interfacing with the client, contractors and other consultants, at all project stages 
  </li><li>Cost planning and estimating, including preparation and presentation of the indicative cost budgets, elemental cost plans and also value management and engineering exercises
  </li><li>Procurement of contractors, including contributing to development of procurement strategies, compiling tender lists, preparing and issuing tender documentation, undertaking tender evaluations through to recommendation and agreement
  </li><li>Preparation of contract documentation
  </li><li>Issuing of written instructions on behalf of employer where authorised to do so and ensuring contractor compliance.
  </li><li>Dealing with post contract variations and changes in accordance with valuation rules
  </li><li>Interim valuations and final account, including detailed review and checking of applications, preparing certificates, negotiations on behalf of or acting for our clients.
  </li><li>Contributing to dispute resolution matters, including strategy development as required
  </li><li>Ensuring adherence to quality assurance and inspection process and procedures for the commissions, including identifying need for separate compliance specialists as necessary to carry out inspections and provide advice
  </li><li>Establishing project completion status by ensuring all project completion requirements are fulfilled, including contractor’s submission of all necessary documentation, executed warranties/guarantees are in place and all test and commissioning certificates are in place
  </li><li>Ensuring a high standard of accuracy by taking personal responsibility for making checks on own work quality, including written and calculated tasks
  </li><li>Promoting commercial awareness
  </li><li>Holding of all information received from client as confidential unless express permission has been given to divulge
  </li><li>Acting in the client’s best interests by ensuring that they fulfil their contractual obligations
  </li><li>Providing an overseeing role to other more junior members of the team and providing guidance and support to them as appropriate
    </li>
    </ul>
    <h3>Knowledge & skills</h3>
    <ul>
<li>A recognised Degree Qualification in Quantity Surveying or equivalent
</li><li>Chartered MRICS Quantity Surveyor is essential
</li><li>Client focussed with ability to develop long term relationships
</li><li>Depth of experience in the profession, gained in a professional consultancy environment
</li><li>Strong technical grounding in the core areas of project and cost management
</li><li>Experience in performing the role of Senior Cost Consultant/Employers Agent and acting as commission lead from inception through to completion
</li><li>Experience in public or private sector residential, education, renewables markets advantageous
</li><li>Good depth of knowledge of the main procurement methods and their suitability for varying types of project
</li><li>Good depth of knowledge the main forms of contract, particularly JCT Design and Build, and their provisions around valuing variations/change, payment, programme, claims and notice requirements
</li><li>Ability to administer contracts and/or act as Employer’s Agent in accordance with RICS professional guidance
</li><li>Ability to analyse, test and challenge data to ensure accuracy of results (programme, cost and quality)
</li><li>Ability to analyse and interpret contract provisions in order to provide professional advice to clients
</li><li>Knowledge and ability to apply dispute avoidance and negotiation techniques as necessary
</li><li>Confident and proficient in the use MS Excel and MS Word
</li><li>Knowledge and experience in supporting BIM2 on commission advantageous
</li><li>Confident ability with a range of construction cost management related software
  </li>
    </ul>
    <h3>Person</h3>
    <ul>
<li>Independent ‘self-starter’ and ability to work with minimal supervision;
</li><li>Enthusiastic and highly motivated;
</li><li>Experience in delivery of successful client focused service
</li><li>Demonstrates strong ethical conduct
</li><li>Demonstrates high standard of professional conduct
</li><li>Excellent communication and presentation skills
</li><li>Able to provide guidance and advice clearly and professionally
</li><li>Ability to work successfully within a team
</li><li>Excellent analytical skills
</li><li>Ability to solve problems
</li><li>Ability to perform under pressure
</li>
    </ul>
    <p>We are looking for an experienced Cost Consultant to be based on our head office in Wokingham, with the requirement to travel to project locations throughout the UK as necessary.</p>
    <p>The role is offered on a full time and permanent basis. </p>
    <p>In return, Procom will offer the successful candidate a competitive salary and benefits commensurate with the role.</p>
    <p>If this role excites you and you’re interested in applying, please send your current CV to: <a href="mailto:pcorfe@procom-uk.com">pcorfe@procom-uk.com</a></p>
    <p>Further details about Procom can be found on our website: <a href={`/about`} onclick={e => linkClick(e, `/about`)}>https://www.procom-uk.com/about</a></p>
  </div>
);
