import { PageListItem } from '@appzuka/rxnano';

import { aboutPages } from 'site/pages/about/about';
import { joinusPages } from 'site/pages/joinus/joinus';
import { homePages } from 'site/pages/home/home';
import { fontsPages } from 'site/pages/fonts/fontsPages';
import { notFoundPage } from '@appzuka/rxnano';
import { contactPage } from '@appzuka/rxnano';
import { contactForm } from '@appzuka/rxnano-contactform';
import { SiteData } from 'site/siteContent';

import { projectPages } from 'site/pages/projects/about';


// import { contactPage } from 'pages/contact';
import { termsPages } from '@appzuka/rxnano';
import { servicePages } from 'site/pages/services/services';
import { sectorPages } from 'site/pages/sectors/sectors';
import { projectPageList } from 'site/pages/about/projects';
import { SectorBanner, bannerTitle } from 'site/components/sectorBanner';

import { ContactAddressList, PrivacyContacts, DropMenuContent as dropmenu } from 'site/siteContent';
import { linkClick } from '@appzuka/rxnano';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

const CommitInfo = require('virtual/commitinfo.js');

// FIXME: Fix for narrow screens
// NOTE: Only if DropMenuContent in SiteContent is a function
const siteLinks = <>
  <div class='hovermenucontent'>
    { () => (dropmenu as Function)(window.location.pathname) }
  </div>
</>

// Copy submenu into each pagex
const pageData = (pages) => {
    return(pages.content.map(k => {return {...k, subMenuClass: pages.subMenuClass, subMenu: pages.subMenu}}))
};

// Pass in an empty function to omit the form
const siteContactForm = (pco) => contactForm(pco, {
    testMode: !CommitInfo.production || CommitInfo.test,
    sourceSite: SiteData.siteName,
    highlight: SiteData.highlight,
    commitVersion: CommitInfo.commit
    });

const siteContactText = 
    <>
        <div class='contact-text'>
            <p>You can get in touch with us through any of the following ways:</p>
            <ul>
                <li>Complete the contact form below</li>
                <li>E-mail us directly at <a href="mailto:info@procom-uk.com">info@procom-uk.com</a></li>
            </ul>
            <p>We will respond to you as soon as we can.</p>
        </div>
    </>

// Need to change the inferred type to allow a function as the image
interface PageData {
content: {
  pageLocation: string;
  image: {
      imageName: string;
      title: string;
  } | any;
  pageTitle: string;
  name: string;
  content: (pco: any) => void;
}[];
subMenu: never[];
}

const ProcomContactPage:PageData = contactPage([<><h2>Contact Details</h2><p>Below are contact details for our offices in Wokingham and Birmingham.</p></>,
  ...ContactAddressList, siteContactText], siteContactForm);
ProcomContactPage.content[0].image = () => SectorBanner(`banners/contact`, () => bannerTitle('Contact Us', undefined), undefined, undefined);

const ProcomTermsPages = termsPages(PrivacyContacts);
ProcomTermsPages.content = ProcomTermsPages.content.map(p => {
  if (p.name === 'terms') { p.image = () => SectorBanner(`banners/terms`, () => bannerTitle('Terms', undefined), undefined, undefined)};
  if (p.name === 'privacy') { p.image = () => SectorBanner(`banners/privacy`, () => bannerTitle('Privacy', undefined), undefined, undefined)};
  return p
});

const pageList : PageListItem[] = [
    ...pageData(notFoundPage('banners/skyscrapers', siteLinks)),
    ...pageData(ProcomContactPage),
    ...pageData(homePages),
    ...pageData(servicePages),
    ...pageData(sectorPages),
    ...pageData(aboutPages),
    ...pageData(joinusPages),
    ...pageData(projectPageList),
    ...pageData(fontsPages),
    ...pageData(ProcomTermsPages),
    ...pageData(projectPages)
];

export {
    pageList
};
  
