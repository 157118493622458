
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We offer our clients a holistic contracts service. At Procom we have extensive experience in the negotiation, agreement and preparation of commercial construction and engineering contracts.')
elementClose('p');
elementOpen('p');
text('Our expertise extends to all of the most widely used industry standard form contracts including the NEC3 and JCT suites, IChemE, PPC2000, TPC and many more. We can even offer our clients a bespoke contract drafting service, if the industry standard form contracts don’t meet their needs.')
elementClose('p');
elementOpen('p');
text('Our focus remains upon securing the best contracting terms for our clients, taking into consideration their attitude to risk allocation / sharing and risk premiums.')
elementClose('p');
elementOpen('p');
text('Our knowledge and experience also extends to ancillary contracts to the main building and engineering contracts. We are able to offer advice and guidance in relation to performance bonds, collateral warranties, retention bonds, vesting certificates, licences for alterations and many more.')
elementClose('p');
elementOpen('p');
text('Our contracts solutions include:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Construction contract selection advice`);elementClose('li');
elementOpen('li');text(`Contract conditions; negotiation and agreement`);elementClose('li');
elementOpen('li');text(`Contract price negotiation`);elementClose('li');
elementOpen('li');text(`Contract preparation; engrossments and execution`);elementClose('li');
elementOpen('li');text(`Advice and preparation of other construction / property related contracts / matters; insurance, collateral warranties, performance bonds et al.`);elementClose('li');
elementClose('ul');
elementClose('div');
  elementClose('div');
  return el;
};