import { patch, JSXFactory, CustomElementHandler, render } from '@appzuka/rxnano';

import { pixLoadedObservable, linkClick } from '@appzuka/rxnano';
// import { PageListItem } from 'src/basePages';

import { first } from 'rxjs/operators';

import { exportedSiteData } from '@appzuka/rxnano'

import './sectorBanner.scss';

// import './imageBanner.scss';

interface ServiceLinkItem {
  label: string
  shortLabel?: string
  link?: string
}

type bannerFunction = (any) => void;

type bannerTitle = string | Node;

interface PageListItem {
  pageLocation: string
  content: any
  image: bannerFunction | string | [string, bannerTitle]
  pageTitle: string
  subMenu?: ServiceLinkItem[]
  name?: string
  index?: boolean
  hide?: boolean
}

const SectorBanner = (image, title, sectionHome?: { label: string, link: string }, nextPage?: { label: string, link: string }) => {

  // const baseName = linkBase.charAt(0).toUpperCase() + linkBase.slice(1);
  <render>
    <div class="sector-banner-wrapper">
      <div class="banner-image-wrapper">
        {() => renderImageBanner(image, 'title')}
        <div class="imagefilter"></div>
        <div class="banner-image-wrapper__nav-wrapper">
          <div class="banner-image-wrapper__nav-margin">
            {sectionHome ? <a href={`${sectionHome.link}`} onclick={e => linkClick(e, `${sectionHome.link}`)}><p>&lt; {sectionHome.label}</p></a> : ''}
            {nextPage ? <a href={`${nextPage.link}`} onclick={e => linkClick(e, `${nextPage.link}`)}><p>{nextPage.label} &gt;</p></a> : ''}
          </div>
        </div>
      </div>
      <div id="sector-banner-title">
        {title}
      </div>
    </div>
  </render>

}

const bannerTitle = (sector, tagline) => {
  return (
    <render>
      <div class="sector-banner-title-wrapper">
        <div class="sector-banner-title-wrapper__margin-wrapper">
          <div class="sector-banner-title-heading-wrapper">
            <h2>{sector}</h2>
          </div>
          { () => tagline ? render(<>
            <p>{tagline}</p>
            <div class="sector-divider"></div>
          </>) : ''}
        </div>
      </div>
    </render>
  );
}

const renderImageBanner = (imageName, title) => {

  const multiPix = (ap4) => {

    let variantArray = imageName !== 'banners/londonhome'
      ? [
        { variant: 'mobile', media: '(max-width: 550px)' },
        { variant: 'wide', media: '(min-width: 551px) and (max-width: 1199px)' },
        { variant: 'pano', media: '(min-width: 1200px)' }
      ]
      : [
        { variant: 'mobile', media: '(max-width: 900px)' },
        { variant: 'wide', media: '(min-width: 901px) and (max-width: 1199px)' },
        { variant: 'pano', media: '(min-width: 1200px)' }
      ];


    if (ap4.length > 0) {
      // FIXME: Log errors to console
      const defaultPix = ap4.filter(p => ((p.file === imageName) && (p.variant === 'wide')))[0];
      const defaultSize = defaultPix.sizes.sort((a, b) => { return ((a - 1024) ** 2 < (b - 1024) ** 2) ? -1 : +1 })[0]; // The size closest to 1024

      variantArray.map(({ variant, media }, i) => {
        const mobilePixArray = ap4.filter(p => ((p.file === imageName) && (p.variant === variant)));
        if (mobilePixArray.length > 0) {
          const mobilePix = mobilePixArray[0];
          const imageBase = `${exportedSiteData.assetBase}${mobilePix.pathprefix}/${[mobilePix.file, mobilePix.variant, mobilePix.hash].filter(p => p).join('-')}`
          const webpSrc = mobilePix.sizes.map(s => `${imageBase}-${s}.webp ${s}w`).join();
          const jpgSrc = mobilePix.sizes.map(s => `${imageBase}-${s}.jpg ${s}w`).join();

          <render>
            <source type='image/webp' srcset={webpSrc} media={media} />
            <source type='image/jpeg' srcset={jpgSrc} media={media} />
          </render>
        } else {
          console.log(`*** Image ${imageName} variant ${variant} not found in pixlist`)
        }
      });

      <render>
        <img src={`${exportedSiteData.assetBase}${defaultPix.pathprefix}/${[defaultPix.file, defaultPix.variant, defaultPix.hash, defaultSize].join('-')}.jpg`} />
      </render>
    }
  }

  const w = window.innerWidth;
  const h = w < 550 ? 0.5 : w < 1024 ? 0.333 : 0.25;
  const svginline = `data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 ${100 * h}"><rect width="680" height="${100 * h}"  style="fill:%23eee" />
    </svg>`;

  <render>
    <picture id='multi-aspect-pix'>
      <img src={svginline} />
    </picture>
  </render>

  // Replace the image once the pixlist is available
  pixLoadedObservable
    .pipe(
      first(a => a.length > 0)
    )
    .subscribe(ap => {
      const el2 = document.getElementById('multi-aspect-pix') as HTMLDivElement;
      patch(el2, () => multiPix(ap));
    })
}

export {
  SectorBanner,
  bannerTitle
};
