import { JSXFactory, CustomElementHandler, render } from '@appzuka/rxnano';
import { sitePix, linkClick } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

import './projects.scss';

const divider = () => {
  <render>
    <div class='block-divider'></div>
  </render>
}


/*
41 Lothbury
Procom’s involvement was BQ Provider working as part of the Wates integrated project team. Or scope was split into 3 district areas:

 

Works to existing features (Internal) – Heritage Areas – Production of detailed CostX BQs using SMM7 for Internal Grade 1 and 2 listed internal works
Additional Scope to New Areas – Production of detailed BQs using SMM7 for all internal works to Ground, 1st and Mezzanine Floors
Add and Omit on General Drawing Updates – Audit of the latest drawings and a general add an omit exercise on the drawings that have changed.


International real estate developer
Pembroke has signed up Wates to
undertake a £53m refurbishment and
extension of a grade II listed former
bank HQ in London’s square mile.

Wates Construction is returning to the 41 Lothbury
site 18 years after completing a previous overhaul
of the building.
The 130,000 sq ft office building was originally built
to serve as the headquarters for Natwest Bank.

The Edwardian building features heritage assets
including iconic stone columns, an original
checkerboard marble floor and a grand central
atrium.
The programme of work will transform existing
rooftop space on floor seven and introduce an
eighth floor with new outdoor terraces offering
spectacular City skyline views.

The iconic Banking Hall will also receive a fresh
injection of high-quality finishes including a new
hospitality-style reception, agile breakout spaces
and an impressive entrance with statement lighting
and artwork by internationally renowned designer,
Jason Bruges.



*/


const projectList = [
  {
    page: 'chelseawharf',
    image: 'case/chelseawharf',
    title: 'Chelsea Wharf',
    summary: [
      'Project Value: £170m',
      'Contract Duration: 3 months'
    ],
    preview: 'Chelsea Waterfront is a unique residential-led development.',
    body: `Chelsea Waterfront is a unique residential-led development combining high-specification interiors, exceptional public amenity space and a prominent riverside location with the rich history of the former Lots Road Power Station.
    The master plan included the refurbishment and conversion of the iconic red-brick power station for residential use, and construction of a 37-storey tower, a 25-storey tower and several lower-rise blocks on the 4.6ha site, which straddles the boroughs of Kensington & Chelsea and Hammersmith & Fulham.
    `
  },
  {
    page: 'midfieldterminal',
    image: 'case/midfieldterminal',
    title: 'Midfield Terminal Building',
    summary: [
      'Client: J&H Emirates',
      'Services Provided: Commercial & Planning Support',
      'Project Value: $2.94bn',
      'Contract Duration: 4 months'
    ],
    preview: 'Construction of a new passenger terminal building at Abu Dhabi Airport',
    body: `A new terminal building constructed as part of ‘plan Abu Dhabi 2030’, an Emirates-wide scheme to ensure the growth of the region as a business and tourism centre.
    Procom were appointed to undertake preliminary contractual, commercial and planning/programming investigations to facilitate and allow informed choices by the client on how they might proceed in securing maximum entitlement to cost and time recovery under the terms of the subcontract.
    `
  },
  {
    page: 'beddington',
    image: 'case/viridor-beddington',
    title: 'Energy from Waste Facilities',
    summary: [
      'Client: Viridor',
      'Services Provided: QS/Project Management',
      'Project Value: £1.5bn',
      'Contract Duration: ongoing'
    ],
    preview: 'A programme to build Energy From Waste facilities around the UK',
    body: `A programme to build Energy From Waste facilities around the UK, which transform over two million tonnes of materials into high quality recycle and producing over 760 gig watt hours of renewable energy.
    Procom have been appointed to provide Commercial Support across designated Viridor projects.
    `
  },
  {
    page: 'kingswayhouse',
    image: 'case/kingswayhouse',
    title: 'Kingway House',
    summary: [
      'Client: Black Finch Investments',
      'Services Provided: Monitoring Surveying Role',
      'Project Value: £5m',
      'Contract Duration: 13 months'
    ],
    preview: 'The conversion of an existing building into residential units.',
    body: `The development was for the conversion of an existing building into residential units.
    Procom were brought in at the request of the lender, to replace the previous monitoring surveyor.  
    Procom had dual functions for this commission, initially, to review the previous monitoring report and state their finding.  Secondly, Procom took over the role of monitoring surveying services for the remainder of the project.
    `
  },
  {
    page: 'dixiedean',
    image: 'case/dixiedean',
    title: 'Dixie Dean Hotel',
    summary: [
      'Client: Black Finch Investments',
      'Services Provided: Monitoring Surveying Role',
      'Project Value: £3.5m',
      'Contract Duration: 13 months'
    ],
    preview: 'The conversion of an existing building into a luxury hotel and spa.',
    body: `The development was for the conversion of an existing building into a luxury hotel and spa.
    Procom were engaged to provide the role of monitoring surveyor on this project.
    `
  },
  {
    page: 'southey',
    image: 'case/southey',
    title: 'Southey Bowling Club',
    summary: [
      'Client: Ravensgate Developments',
      'Services Provided: Contract and commercial support',
    ],
    preview: 'Procom provided contract and commercial support to the client and dealt with claims and adjudications from the contractor.',
    body: `Residential development comprising 9 houses (3 x 2 bed and 6 x 4 bed) and a 3,500 sq ft Bowling Club/Community Facility.
    Procom provided contract and commercial support to the client and dealt with claims and adjudications from the contractor, Chase Construction.`
  },  {
    page: 'galleonsreach',
    image: 'case/galleonsreach1',
    title: 'Galleons Reach',
    summary: [
      'Client: Surefire Management Services',
      'Project Value: £23m',
    ],
    preview: 'Procom are representing Surefire Management Services on this project involving cladding remediation works to 7 blocks of apartments.',
    body: `Procom are representing Surefire Management Services on this project involving cladding remediation works to 7 blocks of apartments.`
  },
]

const projectsCard = (pco, project) => {

  render(
    <a class='project-card' href={`/about/recentprojects/${project.page}`}
      onclick={(event) => { linkClick(event, `/about/recentprojects/${project.page}`) }}>
      <h2 class='project-card__project-title'>{project.title}</h2>
      <div class='project-card__project-container'>
        {() => sitePix(SiteData.assetBase, `${project.image}`, { class: "project-card__picture" })}
        <div class='project-card__project-desc'>
          <div class='project-card__project-desc-wrapper'>
            <div class='project-card__project-desc-headings'>
              {() => project.summary.map(s => {
                const [heading, value] = s.split(': ');
                render(<p>{heading}:</p>);
              })}
            </div>
            <div class='project-card__project-desc-content'>
              {() => project.summary.map(s => {
                const [heading, value] = s.split(': ');
                render(<p>{value}</p>);
              })}
            </div>
            <div class='project-card__project-desc-preview'>
              <p>{project.preview}</p>
            </div>
          </div>
        </div>
        <div class='project-card__more'>
          <p>Click for more &gt;</p>
        </div>
      </div>
    </a>
  );

}

export const projectsPage = (pco, project) => {
  const featuredProjectList = projectList.filter(p => p.page === project);
  if (featuredProjectList.length > 0) {
    projectList.filter(p => p.page === project).map(p => {
      render(
        <div class='recent-project-wrapper'>
          {() => sitePix(SiteData.assetBase, `${p.image}`)}
          <div class='project-summary'>
            <h2>{p.title}</h2>
            <div class='summary-line'>
              {() => p.summary.map(l => { render(<p>{l}</p>) })}
            </div>
          </div>
          <div class='block-divider'></div>
          <div class='project-body'>
            {() => {
              p.body.split(/\r?\n/).map(l => { render(<p>{l}</p>) })
            }}
          </div>
          <div class='block-divider other-divider'></div>
        </div>
      );
    })
  } else {
    render(<p>Please click on a project below to learn more about some of our recent work.</p>)
  }

  render(<div class='other-projects-wrapper'>
    {() => projectList.filter(p => p.page !== project).map(p => {
      projectsCard(pco, p)
    })}
  </div>);
}

export const projectPageList = {
  content: projectList.map(({ page, title }) => {
    return (
      {
        pageLocation: `about/recentprojects/${page}`,
        image: () => { },
        pageTitle: title,
        content: (pco) => projectsPage(pco, page),
        name: name
      }
    )
  }),
  subMenu: []
};

