import { JSXFactory, render } from '@appzuka/rxnano';

import { pixLoadedObservable, sitePix } from '@appzuka/rxnano';
import { first, map, takeWhile, filter } from 'rxjs/operators';

import { SiteData } from 'site/siteContent';

import { patch } from 'incremental-dom';

import './clients.scss';

let clientEl;

export const clientsPage = (pco) => {

  render(
    <>
      <h2>Clients</h2>
      <p>We are proud to support clients of varying sizes across a range of sectors. Here is a selection of our clients.</p>
      <div ref={(e) => { clientEl = e; return e }} id="client-logo-wrapper" />
    </>
  );

  pixLoadedObservable
    .pipe(
      first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
      map(pl => pl.filter(p => p.file.indexOf('client-logos/') === 0))
    )
    .subscribe(pixList => {
      patch(clientEl, () => {
        pixList.map(p => { sitePix(SiteData.assetBase, p.file); })
      })
    });
}

