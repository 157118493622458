import { PageContent } from '@appzuka/rxnano';

import { completeCallback } from '@appzuka/rxnano';
import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';
import { SectorBanner, bannerTitle } from 'site/components/sectorBanner';

import { linkClick } from '@appzuka/rxnano';

import { projectListByDate } from 'site/pages/sectors/sectors';


import './about.scss';


const aboutContent = () => {
  <render>
    <h2>New Projects</h2>
    <p>New project information added March 23</p>
    { () => projectListByDate({date: '2023-03-09', pathPrefix: 'newcase', exclude: ''})}

  </render>
  completeCallback('about');

}



let pageList = [
  {
    pageLocation: 'newprojects',
    // image: [`banners/${banner}`, 'Services'] as [string, string],
    image: () => SectorBanner(`banners/joinus`, () => bannerTitle('Join Us', null), { link: '/about', label: 'About Procom' }, { link: '/about/people', label: `Back: People` }),
    pageTitle: 'New Projects',
    content: aboutContent,
    index: false,
    name: 'newprojects'
  },
];


const projectPages: PageContent = {
  content: pageList,
  subMenu: []
}

export {
  projectPages
};

