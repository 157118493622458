import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';
import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

// import './people.scss';

export const qualityPage = (pco) => {
    <render>
        <h2>Quality</h2>
        <p>Our focus has always been and remains upon maintaining consistently high standards of quality in the delivery of our services. Repeat business won from our existing client base is testament to our continued success in achieving this. Despite this, we are always striving for new ways to innovate and improve for the benefit of Procom and its clients.</p>
        { () => sitePix(SiteData.assetBase, 'accreditations/iso9001-3') }
        <p>Procom is certified to ISO9001 and we are also CHAS accredited.</p>
        <p>ISO 9001 was first introduced in 1987 and requires organisations to demonstrate that they do what they say they do and that they have a quality management system in place to ensure consistency and improvement; leading to high levels of performance and customer satisfaction. Certified organisations are committed to continuous improvement and are assessed annually to ensure progress is being maintained.</p>
    </render>

}

