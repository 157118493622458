import { PageContent } from '@appzuka/rxnano';

import { completeCallback } from '@appzuka/rxnano';
import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';
import { SectorBanner, bannerTitle } from 'site/components/sectorBanner';
import { brochures } from 'site/components/brochureLinks';

import { linkClick } from '@appzuka/rxnano';

import { valuesPage } from './values';
import { codePage } from './code';
import { newsPage } from './news';
import { peoplePage } from './people';
import { clientsPage } from './clients';
import { testimonialsPage } from './testimonials';
import { qualityPage } from './quality';
import { projectsPage } from './projects';

import './about.scss';


const aboutContent = () => {
  <render>
    <h2>Our Mission</h2>
    <p>To deliver effective management and best value estate and construction solutions always with the aim of exceeding our client’s expectations and upholding our commitment to fairness, honesty and continuous improvement.</p>
    <h2>About Procom</h2>
    <p>Specialising in construction procurement, commercial and project management and forensic cost auditing Procom has earned recognition for its professional approach serving all clients with a good quality service secured by a combination of qualifications, expertise and experience.</p>
    <p>We work in both the public and private sector and for all contracting parties.</p>
    <p>Our approach to business is simple. We are:</p>
    <ul>
      <li>Open</li>
      <li>Honest</li>
      <li>Reliable</li>
      <li>Service focused</li>
      <li>Results driven</li>
    </ul>

    <p>Our philosophy is to build upon our areas of expertise, forming strong relationships with clients and providing the very best teams to provide surety of delivery.</p>
    <p>We have extensive experience of traditional contracts and partnering arrangements including open book contracts, in particular NEC3, PPC 2000 and TPC 2005.</p>

    { brochures }

  </render>
  completeCallback('about');

}

const aboutList = [
  { page: 'About', link: 'about', content: aboutContent, name: 'about', title: 'About', banner: 'aboutblue' },
  { page: 'Code of Conduct', link: 'about/code', content: codePage, name: 'code', title: 'Code of Conduct', banner: 'code' },
  { page: 'Values', link: 'about/values', content: valuesPage, name: 'values', title: 'Our Values', banner: 'values' },
  { page: 'Clients', link: 'about/clients', content: clientsPage, name: 'clients', title: 'Clients', banner: 'clients' },
  { page: 'Testimonials', link: 'about/clients/testimonials', content: testimonialsPage, name: 'testimonials', hide: true, title: 'Testimonials', banner: 'testimonials' },
  { page: 'Quality', link: 'about/quality', content: qualityPage, name: 'quality', title: 'Quality Assurance', banner: 'quality' },
  { page: 'Recent Projects', link: 'about/recentprojects', content: projectsPage, name: 'projects', title: 'Recent Projects', banner: 'about' },
  { page: 'News', link: 'about/news', content: newsPage, name: 'news', hide: true, title: 'News', banner: 'news' },
  { page: 'People', link: 'about/people', content: peoplePage, name: 'people', hide: true, title: 'Our People', banner: 'people' },
];

const nextSector = (current) => {

  const index = aboutList.findIndex(({ name }) => name === current);
  if (index === aboutList.length - 1) {
    return ({ link: '/' + aboutList[1].link, label: `Next: ${aboutList[1].title}` });
  }
  return ({ link: '/' + aboutList[index + 1].link, label: `Next: ${aboutList[index + 1].title}` });

}

let pageList = [
  ...aboutList.map(({ page, link, content, name, title, banner }) => {
    return (
      {
        pageLocation: link,
        // image: [`banners/${banner}`, 'Services'] as [string, string],
        image: name !== 'projects'
        ? () => SectorBanner(`banners/${banner}`, () => bannerTitle(title, null), { link: '/about', label: 'About Procom' }, nextSector(name))
        : () => <render><div class="page-name-banner"><h2>Recent Projects</h2></div></render>,
      pageTitle: title,

        content: content,
        name: name
      }
    )
  })
];

const aboutSubmenu = () => {
  <render>
    <div class='service-sub-menu'>
      {() => aboutList.filter(({ hide }) => !hide).map(({ page, link, content }) => {
        return (<render>
          <a href={'/' + link}
            onclick={e => linkClick(e, '/' + link)}
            class={['submenu-item', window.location.pathname === link ? 'active' : 'non-active'].join(' ')}>
            <p>{page}</p>
          </a>
        </render>)
      })}
    </div>
  </render>
}


const aboutPages: PageContent = {
  content: pageList,
  subMenu: aboutSubmenu
}

export {
  aboutPages
};

