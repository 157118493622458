import { PageContent } from '@appzuka/rxnano';

import { completeCallback } from '@appzuka/rxnano';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

// const fontPage = () => {
//     return(<render>)


// }

const fontPage1 = (family, size, weight) => {
    <render>
        <div class='font-test-block' style={`font-family: ${family}; font-size: ${size}px; font-weight: ${weight};`}>
           <p style='margin: 0;'>font-weight: {weight}. Procom Construction Consultants  About Services Sectors Projects</p>
        </div>
    </render>
}

const fontPage2 = (family, size, weight) => {
    <render>
        <div class='font-test-block' style={`font-family: ${family}; font-size: ${size}px; font-weight: ${weight}; padding-bottom: 10px;`}>
            <p style='margin: 0;'>font-weight: {weight}.</p>
            <p>Specialising in construction procurement, commercial and project management and forensic cost auditing Procom has earned recognition for its professional approach serving all clients with a good quality service secured by a combination of qualifications, expertise and experience.</p>
            <p>Procom can offer clients the expertise to bring confidence and transparency into the target cost and actual cost of contracts.</p>
            <p>Our team have an in-depth understanding of the construction industry and the supply chain and use this combined with technical expertise and proven systems to identify costs which should not be charged to our clients.</p>
        </div>
    </render>
}


const bodyFonts = [
    {
        name: 'calibri',
        sizes: [300,400]
    },
    {
        name: 'open-sans',
        sizes: [300,400]
    },
    {
        name: 'proxima-nova',
        sizes: [100,300,400,500]
    }
]


const fontsPages : PageContent = {
    content: [
        {
            pageLocation: 'fonts',
            image: () => {},
            pageTitle: 'Fonts',
            content: () => {
                ['sofia-pro', 'sofia-pro-condensed'].map(f => {
                    [16,18,20].reverse().map(s => {
                        <render><p style={`font-family: 'sofia-pro'; font-weight: 24px; font-weight: 500;`}>Font-Size: {s} Font-Family: {f}</p></render>
                            [100,200,300,400,500].reverse().map(w => fontPage1(f, s, w));
                        })
                })

                completeCallback('fonts');
            }
        },
        {
            pageLocation: 'copyfonts',
            image: () => {},
            pageTitle: 'Cony Fonts',
            content: () => {
                bodyFonts.map(f => {
                    [18,20].reverse().map(s => {
                        <render><p style={`font-family: 'sofia-pro'; font-weight: 24px; font-weight: 500;`}>Font-Size: {s} Font-Family: {f.name}</p></render>
                            [...f.sizes].reverse().map(w => fontPage2(f, s, w));
                        })
                })

                completeCallback('fonts');
            }
        },
        {
            pageLocation: 'copyfonts2',
            image: () => {},
            pageTitle: 'Cony Fonts',
            content: () => {
                bodyFonts.map(f => {
                    [18].reverse().map(s => {
                        <render><p style={`font-family: 'sofia-pro'; font-weight: 24px; font-weight: 500;`}>Font-Size: {s} Font-Family: {f.name}</p></render>
                            fontPage2(f, s, 300);
                        })
                })

                completeCallback('fonts');
            }
        }
    ],
    subMenu: []

}

export {
    fontsPages
};

