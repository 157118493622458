import { PageContent, pixLoadedObservable, patch } from '@appzuka/rxnano';
import { first, map, takeWhile, filter } from 'rxjs/operators';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

import { articleContent as serviceContent } from './services.md'
import { articleContent as projectMgtContent } from './services-projectmgt.md'
import { articleContent as costAuditingContent } from './services-costauditing.md'
import { articleContent as disputeContent } from './services-dispute.md'
import { articleContent as claddingContent } from './services-cladding.md'
import { articleContent as surveyingContent } from './services-surveying.md'
import { articleContent as surveyContent } from './services-stock-survey.md'


import { articleContent as adminContent } from './cost-admin.md'
import { articleContent as commercialContent } from './cost-commercial.md'
import { articleContent as contractsContent } from './cost-contracts.md'
import { articleContent as planningContent } from './cost-planning.md'
import { articleContent as procureContent } from './cost-procure.md'
import { articleContent as takingContent } from './cost-taking.md'

import { linkClick } from '@appzuka/rxnano';

import { SectorBanner, bannerTitle } from 'site/components/sectorBanner';
import { ServiceIndexBanner } from 'site/components/serviceIndexBanner';
import { CostServiceIndex, CostServiceIndexBullet } from 'site/components/costServiceIndex';
import { brochures } from 'site/components/brochureLinks';

import { projectData } from '../sectors/projectdata';
import { sectorHomeBanner } from '../sectors/sectors';
import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';


import './services.scss';

const boqProjects = require('../sectors/boqprojects.json');
const projects2022 = require('./220120Cases.json');

const boqModals = [
    {
        image: 'boq/output',
        description: 'Snapshot of a full Bill of Quantities produced off the back of the measurements completed in CostX.'
    },
    {
        image: 'boq/plan1',
        description: 'Fully marked up drawing for Drylining (Ceilings and walls) - Plan 1'
    },
    {
        image: 'boq/plan2',
        description: 'Fully marked up drawing for Drylining (Ceilings and walls) - Plan 2'
    },
];

const closeModal = (modalDiv) => {
    modalDiv.remove();
}

const showModalImage = (modalDiv, index) => {
    patch(modalDiv, () => {
        const prevImage = (index+boqModals.length-1) % boqModals.length;
        const nextImage = (index+1) % boqModals.length;
        const thisImage = boqModals[index];
        <render>
            <div class='modal-nav container'>
                <p class='prev-modal' onclick={e=>{showModalImage(modalDiv, prevImage)}}>Prev</p>
                <p class='close-modal' onclick={e => closeModal(modalDiv)}>Close</p>
                <p class='next-modal' onclick={e=>{showModalImage(modalDiv, nextImage)}}>Next</p>
            </div>
            { () => sitePix(SiteData.assetBase, thisImage.image, { mediaSizes: '100vw' })}
            <p class='modal-description'>{thisImage.description}</p>
        </render>
    });
}

const showModal = (index) => {
    let modalDiv = document.createElement("div");
    modalDiv.id = "modal";
    const modal = document.body.prepend(modalDiv);
    showModalImage(modalDiv, index);
}

const billsContent = [
    {
        title: 'Infrastructure',
        image: 'services/boq/infrastructure',
        content: (<div>
            <p>We prepare detailed Bills of Quantities for a wide range of infrastructure projects, from airports, tunnels, bridges and roads to rail, land reclamation, marine works and utilities. Procom have extensive experience of a wide range projects covering road, rail, air and naval sectors with a combined value of projects delivered over £20bn.</p>
<p>We provide advice and Bills of Quantities guidance to our Clients across the UK and around the globe, helping them achieve their goals and redefine their ambitions.</p>
        
        </div>)
    },
        {
        title: 'Cost Budgeting',
        image: 'services/costmgt/cost',
        content: (<div>
            <p>Our cost planning service is designed to facilitate robust commercial planning and management of our clients' capital and revenue projects.</p>
            <p>Our focus is on cost certainty, risk mitigation and delivering best value. We also regularly assist clients in the development and management of their business cases, budgets / budgetary controls, and spend forecasting. Accurately prepared Bills of Quantities are an essential tool for planning and managing project budgets.</p>
            {/* <p>Our cost planning solutions include the following:</p> */}
            {/* <ul>
                <li>Feasibility Studies</li>
                <li>Developing the Business Case</li>
                <li>Detailed Cost Planning</li>
                <li>Lifecycle and Whole Life Costing</li>
                <li>Cash Flow Forecasting</li>
                <li>Value Management and Value Engineering</li>
                <li>Capital Allowances</li>
                <li>Earned Value Analysis</li>
                <li>Commercial Risk Assessment and Management</li>
            </ul> */}
        
        </div>)
    },
        {
        title: 'Dispute Resolution',
        image: 'services/boq/resolution',
        content: (<div>
            <p>A detailed Bills of Quantities report prepared by Procom at a pre-contract stage will set out clear contract terms designed to mitigate the risk of disparity of intent between contracting parties.  Should a dispute arise Procom will aid our clients through management of negotiations.</p>
            <p>We also possess the expertise to undertake on our clients behalf, the complete management of disputes referred to adjudication, arbitration or legal proceedings.</p>
        
        </div>)
    },
        {
        title: 'Measured Design Variation Services',
        image: 'services/boq/measure',
        content: (<div>
            <p>We offer comprehensive measured design variation services throughout the life of your construction project to ensure a positive outcome at the final account stage.</p>
            <p>Using specialist sector expertise and experience we can provide early warning of any significant impact on cost, programme or quality. By forecasting pricing change and programme risk, Procom can predict future events not just report on what has already happened.</p>
        </div>)
    },
        {
        title: 'Project Focused Commercial Support',
        image: 'services/boq/plan',
        content: (<div>
            <p>We also serve as construction cost consultants, providing support, guidance and advice on project finances and contractual relationships. We handle and manage various aspects of your project, including negotiating and preparing contractual relationships and managing the on-site construction process so that you don't have to. This allows you to focus on other core aspects of the construction works.</p>
        
        </div>)
    },
        {
        title: 'Quantities Verification',
        image: 'services/boq/budget',
        content: (<div>
<p>Our depth of experience in preparing Bills of Quantities ensures we can help you at times of dispute and differences. We can perform forensic reviews on your project to determine the correct quantities of work, ensuring you have the support you need throughout.</p>
            <p>We have been working on target cost and cost reimbursable contracts for 14 years and have provided advice and support on more than 100 contracts to date, and have audited in excess of £2.5Bn of costs, helping our clients save more than £40m in cost to date.</p>
            <p>We have demonstrable evidence that our involvement on projects pays for itself many times over, so our services are not an additional cost to our clients.</p>
        </div>)
    }
];

const projectSectorList = (area: string) => {
    <render>
        <div class="services-heading-divider"></div>
    <h2>Project Portfolio</h2>
    <p>Below are a selection of projects where Procom have provided {area} services to our clients.  Click on a sector to view some of the projects Procom have helped our clients in that sector or contact us to learn more.</p>
    { sectorHomeBanner }
    </render>
}

const Sectors = {
    "E": 'Education',
    "R": 'Residential',
    'L&S': 'Leisure & Sport',
    'O&C': 'Office & Commercial',
    'T&I': 'Transport & Infrastructure',
    'RE': 'Renewables',
    'D':'Defence'
};

const cleanFileName = (name) => {
    return (name.replace(/(["()_& \s'$`\\])/g, '-').replace(/--+/g, '-').toLowerCase()).replace(/\.(.*)$/, '');
}

const billsQtyContent = () => {
    let pos = 0;
    let el;
    let pixToShow = 10;
    <render>
<p>Bills of Quantities are an industry standard approach to analysing a project.  It involves categorising and describing the materials and time required to complete the project into a recognised format. This can be used to ensure that all contractors prepare their bids based on the same quantities so that the tendering process is accurate and fair.</p>
<p>Procom has been providing first-class Construction consultancy services since 2009 and have expertise in preparing Bills of Quantities in a variety of formats, including SMM, NRM, CESMM etc or just Builders Quants. We specialise in Bills of Quantities for 2 stage tenders.</p>
<p>Procom have invested in CostX so that we use the latest technology to provide our clients with the most efficient and most accurate results including a clear audit trail.  We can provide our clients with complete visibility over the quantities including a 2D and 3D measurement tool that provides take-offs that are live-linked to bills.</p>
<p>The prepared document can be used for post contract control and Procom can monitor actual expenditure to control costs both during the project and after project completion.</p>
        <div id='cost-mgt-wrapper'>
            { () => billsContent.map((b,i) => {
                    // CostServiceIndex(b.image, i, b.title, b.content)
                    CostServiceIndexBullet(b.title, b.content)
                })
            }
        </div
        
        >
        <h2>Bills of Quantities Example Output</h2>
        <div class='boq-example'>
            <div>
            <p>This example shows a fully marked up drawing for Drylining (Ceilings and walls). These are exported into an easy to read, professional PDF.</p>
            <p>Also shown is a snapshot of a full Bill of Quantities produced off the back of the measurements completed in CostX.</p>
            </div>

            <div class='example-thumbnail' onclick={e=> showModal(0)}>{ () => sitePix(SiteData.assetBase, boqModals[2].image, { mediaSizes: '500px' })}</div>
        </div>
        <div>
            <h2>Bills of Quantities Project Portfolio</h2>
            <p>Below are a selection of projects Procom are proud to have provided Bills of Quantities services for:</p>
        </div>
        <div class='projects-wrapper' ref={(r) => el=r}>

        </div>
        {/* <div class='boqticker-wrapper'>
        <div class='boqticker-container' ref={(r) => el=r}>
        { () =>
            boqProjects.sort((a, b) => parseInt(a.Year) < parseInt(b.Year) ? 1 : -1  ).map((p,i) => {
                const sectorName = Sectors[p.Sector] || "";

                <render>
                    <div class={`boq-arrow-left boq-${(i % 3)+1}`}></div>
                    <div class={`boq-project boq-${(i % 3)+1}`}>
                        <h2>{p.Project}</h2>
                        <p>Client: {p.Client}</p>
                        <div class='sector-year'>
                            <p>{sectorName}</p>
                            <p>20{p.Year}</p>
                        </div>
                    </div>
                    <div class={`boq-arrow-right boq-${(i % 3)+1}`}></div>
                </render>
            })
        }
        </div>
        </div> */}
    </render>
    {/* const moveTicker = () => {
        pos = pos - 1;
        el.style.left = pos+'px';

    }
    let id = setInterval(moveTicker, 20); */}

    pixLoadedObservable
    .pipe(
      first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
      map(pl => pl.filter(p => p.file.indexOf('projects2022/') === 0))
    )
    .subscribe(pixList => {
      // Create a list of all loaded pictures
      const pixLoaded = pixList.map(p => p.file);

      const missingPhotos = projects2022
      .filter(p => !pixLoaded.includes(`projects2022/${cleanFileName(p["PHOTO"])}`));
      console.log(`Projects excluded due to missing photos: ${missingPhotos.length}`);
      console.log(missingPhotos);

      const selectedProjects = projects2022
      .filter(p => pixLoaded.includes(`projects2022/${cleanFileName(p["PHOTO"])}`))
      .filter(p => p["SCOPE OF SERVICES PROVIDED"].match(/bill/i))
      .sort((a, b) => a["YEAR"] < b["YEAR"] ? 1 : -1  );
      const numProjects = selectedProjects.length;

      const refreshProjects = () => {
        selectedProjects
        .slice(0,pixToShow)
        .map((p,i) => {
            const sectorName = Sectors[p["SECTOR"]] || '';

            <render>
                <div class='boq-project'>
                    { () => sitePix(SiteData.assetBase, `projects2022/${cleanFileName(p["PHOTO"])}`, { mediaSizes: '200px' }) }
                    <div class='boq-project-content'>
                        <h2>{p["PROJECT TITLE"]}</h2>
                        <p>Sector: {sectorName}</p>
                        <p>Client: {p["CLIENT"]}</p>
                        <p>Date: 20{p["YEAR"]}</p>
                        <p>Services Provided: {p["SCOPE OF SERVICES PROVIDED"]}</p>
                    </div>
                 </div>

            </render>
        });
        if (pixToShow < numProjects) {
            <render>
                <div class='boq-projects-more' onclick={e => {

pixToShow = pixToShow+10;
if (pixToShow > numProjects) {
    pixToShow = numProjects
}
console.log(`To Show: ${pixToShow} ${numProjects}`);
patch(el, refreshProjects);
}}>
            <p>Click Here to View More Projects</p></div>
            </render>
        }

        }
        patch(el, refreshProjects);



    });


}
const costMgtContent = () => {
    <render>
        <p>Please see the items below to learn more about the services offered by Procom in this area.</p>
        <div id='cost-mgt-wrapper'>
            { () => CostServiceIndex('services/costmgt/cost', 1, 'Cost Planning', planningContent) }
            { () => CostServiceIndex('services/costmgt/taking', 2, 'Taking-Off and Bill Preparation Services', takingContent) }
            { () => CostServiceIndex('services/costmgt/procure', 3, 'Procurement', procureContent) }
            { () => CostServiceIndex('services/costmgt/contract', 4, 'Contracts', contractsContent) }
            { () => CostServiceIndex('services/costmgt/manage', 5, 'Post Contract Commercial Management', commercialContent) }
            { () => CostServiceIndex('services/costmgt/admin', 6, 'Contract Administration', adminContent) }
        </div>

    </render>
}

const monitoringContent = () => {
    <render>
        <p>Typically, the Monitoring Surveyor observes and comments on the project planning and delivery process. The various services provided by the Monitoring Surveyor include:</p>
        <ul>
            <li>Land and property acquisition matters</li>
            <li>Statutory compliance</li>
            <li>Competency of the developer, its team and any proposed project management systems</li>
            <li>Financial appraisals</li>
            <li>Development finance, consultancy and construction agreements</li>
            <li>Construction costs and programmes</li>
            <li>Design and construction quality</li>
        </ul>
        <h4>Benefits of Project Monitoring</h4>
        <ul>
            <li><span class='monitoring-subtitle'>Early warning</span> – using specialist sector expertise and experience to provide early warning of any significant impact on cost, programme or quality</li>
            <li><span class='monitoring-subtitle'>Insight and opinion</span> – providing informed insight and opinion on the basis of specialist sector expertise and experience</li>
            <li><span class='monitoring-subtitle'>Forecasting</span> – pricing change and programme risk, predicting future events not just reporting on what has already happened</li>
            <li><span class='monitoring-subtitle'>Greater certainty</span> – profiling the project risks and providing impartial advice on alternatives for better management and mitigation of risk</li>
            <li><span class='monitoring-subtitle'>Project controls</span> – ongoing testing of the robustness of the design and project management, the procurement and construction management, the change control process, the safety, quality and performance management</li>
            <li><span class='monitoring-subtitle'>Sharing responsibility</span> – defining the respective roles of the banks and of Project Monitors, thereby reducing the banks’ responsibility and exposure to potential errors or costly delays</li>
            <li><span class='monitoring-subtitle'>Transparency</span> – regularly updating the project risk profile and advising on the evolution of each risk and the project overall.</li>
        </ul>
    </render>
}



const projectList = (sector) => {
    const sectorList = projectData.filter(p => p.sector === sector);

    // FIXME: This is always empty because the sectors do not match

    sectorList.map(p => {
        let firstImage = Array.isArray(p.images) ? p.images[0] : p.images+'1';
        <render>
            <h2>{p.title}</h2>
            { () => sitePix(SiteData.assetBase, `projects/${firstImage.replace('.jpg', '').replace('.png', '').toLowerCase()}`) }
        </render>
    });
}


const sectorContent = (sector, label, content) => {

    <render>
        <h2>{sector}</h2>
        { content }
        { () => projectList(label) }
    </render>
}

// label is bpth the url of the page and the image

const serviceList = [
    {sector: 'Cost Management', name: 'cost-management', label: 'cost-management',
        tagline: 'We offer a full range of pre and post contract of cost management / quantity surveying services to our clients.',
        content: costMgtContent },
    {sector: 'Bills of Quantities', name: 'bills-of-quantities', label: 'bills-of-quantities',
        tagline: 'Our team of quantity surveyors are highly experienced in producing bills of quantities for complex civil engineering and construction projects.',
        content: billsQtyContent },
    {sector: 'Stock Condition Surveys', name: 'stock-surveys', label: 'stock-surveys',
        tagline: 'Understanding the condition of your stock.',
        content: surveyContent },
    {sector: 'Project Management', name: 'project-management', label: 'project-management',
        tagline: 'Our experienced project managers are familiar with all aspects of both pre- and post- contract project management duties.',
        content: projectMgtContent },
    {sector: 'Monitoring Surveyors', name: 'monitoring-surveyors', label: 'monitoring-surveyors',
        tagline: 'The Monitoring Surveyor is there to advise the Client on his/her interest in, and risks associated with, the development.',
        content: monitoringContent },
    {sector: 'Forensic Cost Auditing', name: 'forensic-cost-auditing', label: 'forensic-cost-auditing',
        tagline: 'Providing clients with robust cost verification on target cost and cost reimbursable contracts.',
        content: costAuditingContent },
    {sector: 'Dispute Resolution', name: 'dispute-resolution', label: 'dispute-resolution',
        tagline: 'We offer a comprehensive service in dispute avoidance and resolution.',
        content: disputeContent },
    {sector: 'Building Surveying', name: 'building-surveying', label: 'building-surveying',
        tagline: 'Procom partner with Panodrone to provide industry leading drone surveying services.',
        content: surveyingContent },
    {sector: 'Cladding Remediation', name: 'cladding', label: 'cladding',
        tagline: 'With our experienced in-house team of professionals, Procom are able to offer the most appropriate Cladding Remediation solution.',
        content: claddingContent }
];

const nextSector = (current) => {

    const index = serviceList.findIndex(({label}) => label === current);
    if (index === serviceList.length-1) {
        return({link: `/services/${serviceList[0].sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`, label: `Next Service: ${serviceList[0].sector}`});
    }
    return({link: `/services/${serviceList[index+1].sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`, label: `Next Service: ${serviceList[index+1].sector}`});

}

let pageList = [
    {
        pageLocation: 'services',
        image: () => ServiceIndexBanner(() => <render>Our Services</render>),
        pageTitle: 'Services',
        content: () => {
          serviceContent();
          brochures();
        },
        name: `service-index`
    },
    ...serviceList.map(({sector, name, label, tagline, content}) => {
        return(
        {
            pageLocation: `services/${sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`,
            image: (pco) => SectorBanner(`banners/services/${label=== 'stock-surveys'?'building-surveying':label}`, () => bannerTitle(sector, tagline), {link: '/services', label: 'All Services'}, nextSector(label)),
            pageTitle: 'Services',
            content: () => {
                sectorContent(sector, label, content);
                if (label !== 'stock-surveys') {
                    projectSectorList(sector);
                }
            },
            name: name
        }
    )})
];


const serviceSubmenu = () => {
    <render>
        <div class='service-sub-menu'>
            {() => serviceList.map(({sector}) => {
                const link = `/services/${sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`;
                return(<render>
                    <a href={link}
                        onclick={e => linkClick(e, link)}
                        class={['submenu-item', window.location.pathname===link?'active':'non-active'].join(' ')}>
                        <p>{sector}</p>
                    </a>
                </render>)
            })}
        </div>
    </render>
}


const servicePages : PageContent = {
    content: pageList,
    // subMenuClass: 'submenu-noheader',
    // subMenu: [
    //     ...serviceList.map(s => {return({ label: s, shortLabel: 'terms', link: `/services/${s.replace(' ', '-').toLowerCase()}`})})
    // ]
    subMenu: serviceSubmenu
}

export {
    servicePages
};

