import { JSXFactory, render } from '@appzuka/rxnano';

import './testimonials.scss';

var quotes =  [
  "The guys we've had from you have been invaluable!",
  "I engaged Procom when I was Interim Group Procurement Director at Hyde Group. Colin Hardiman was the main contact and he proved to be an exceptionally professional and knowledgeable individual.",
  "Procom had spotted the problems before they arose, and offered the solutions.",
  "Procom are one of our framework consultants providing procurement and cost management support to our clients procuring public sector repairs and maintenance contracts circa £100m per annum. Their service was exceptional, always timely and delivered great results.",
  "Procom understood all of the environmental issues, and stopped us from making a costly mistake, twice!",
  "We were promised the A-team and that is exactly what we got. The best they had really looked after us.",
  "Days from failure we called Procom and they turned it all around, and will be with us from the start next time.",
  "Procom were right on the money. I wouldn't like to have undertaken this project without their know-how.",
  "The service provided was timely and they interacted with multiple and varied groups of staff and residents in an engaging way."];

export const testimonialsPage = (pco) => {

  render(
    <>
      <h2>Testimonials</h2>
      <p>There is no better reference than our current customers. Below you can read more about what some of them have to say about working with Procom.</p>
      <div class='quote-wrapper'>
        {() => {
          quotes.map((q,i) => {
            <render><p class={`quote-wrapper__quote quote-wrapper__${i % 2 ? 'even' : 'odd'}`}>{q}</p></render>
          })
        }}
      </div>
    </>
  );


}

