import { JSXFactory, CustomElementHandler, render } from '@appzuka/rxnano';

import { first } from 'rxjs/operators';

import { exportedSiteData, pixLoadedObservable, patch } from '@appzuka/rxnano'

import './costServiceIndex.scss';

interface ServiceLinkItem {
    label: string
    shortLabel?: string
    link?: string
}

type bannerFunction = (any) => void;

type bannerTitle = string | Node;

interface PageListItem {
    pageLocation: string
    content: any
    image: bannerFunction | string | [string, bannerTitle]
    pageTitle: string
    subMenu?: ServiceLinkItem[]
    name?: string
    index?: boolean
    hide?: boolean
}

const CostServiceIndex = (image, index, title, content : (string | any)) => {
    <render>
        
        <div class="cost-service-index-row-wrapper">
            <div class="row-heading">
                <h2>{title}</h2>
                <div class="heading-divider"></div>
            </div>
            { () => renderImageBanner(index, image)}
            { () => {
                if (typeof content === "string") {
                    const content_array = content.split("\n");
                    content_array.map(p => render(<p>{p}</p>));
                } else {
                    content();
                }
            }
            }
        </div>

    </render>

}
const CostServiceIndexBullet = (title, content : (string | any)) => {
    <render>

        
        <div class="cost-service-index-row-wrapper-bullet">
        <h2>{title}</h2>
        { () =>  {if (typeof content === "string") {
                    const content_array = content.split("\n");
                    content_array.map(p => render(<p>{p}</p>));
                } else {
                    content();
                }
            }
        }
        </div>
    </render>

}

const renderImageBanner = (index, image) => {

    const serviceBannerPlaceholder = () => {
        const w = window.innerWidth;
        const h = w<800 ? 0.5 : 0.4;
        const svginline = `data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 ${100*h}"><rect width="680" height="${100*h}"  style="fill:%23eee" />
        </svg>`;
        <render>
            <img src={svginline}/>
        </render>
    }


    const multiPix = (ap4) => {

            const indexTileArr = ap4.filter(p => p.file === image);
            if (indexTileArr.length > 0) {
                const pix = indexTileArr[0];
                const imageBase = `${exportedSiteData.assetBase}${pix.pathprefix}/${[pix.file, pix.variant, pix.hash].filter(p=>p).join('-')}`
                const webpSrc = pix.sizes.map(s => `${imageBase}-${s}.webp ${s}w`).join();
                const jpgSrc = pix.sizes.map(s => `${imageBase}-${s}.jpg ${s}w`).join();
                <render>
                    <picture class={pix.cssclass}>
                        <source type='image/webp' srcset={webpSrc} sizes="20vw" />
                        <source type='image/jpeg' srcset={jpgSrc} sizes="20vw" />
                        <img src={`${exportedSiteData.assetBase}${pix.pathprefix}/${[pix.file, pix.variant, pix.hash, 400].filter(x => x).join('-')}.jpg`} />
                    </picture>
                </render>


            } else {
                console.log(`Cost index pix: ${image} not found`);
                { serviceBannerPlaceholder }
            }


    }

    <render>
        <div class="cost-index-tile" id={`cost-index-tile-${index}`}>
            { serviceBannerPlaceholder }
        </div>
    </render>

    // Replace the image once the pixlist is available
    pixLoadedObservable
    .pipe(
        first(a => a.length > 0)
    )
    .subscribe(ap => {
        const el2 = document.getElementById(`cost-index-tile-${index}`) as HTMLDivElement;
        patch(el2, () => multiPix(ap));
    })
}

export {
    CostServiceIndex,
    CostServiceIndexBullet
};
