
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Procom can offer the complete commercial management of the post contract phase of construction and engineering projects. Our focus is upon ensuring that our clients commercial interests are protected, as well as seeing that they are meeting their contractual obligations and the requirements of relevant legislation.')
elementClose('p');
elementOpen('p');
text('Procom is also able to offer clients assistance with commercial spend and cash flow reporting as well as developing and managing budgetary controls.')
elementClose('p');
elementOpen('p');
text('Our commercial management solutions include:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Administering valuations`);elementClose('li');
elementOpen('li');text(`Administration of post contract change`);elementClose('li');
elementOpen('li');text(`Management of loss and / or expense and extension of time claims`);elementClose('li');
elementOpen('li');text(`Final account negotiations and agreement`);elementClose('li');
elementOpen('li');text(`Forensic cost auditing`);elementClose('li');
elementOpen('li');text(`Earned Value Analysis`);elementClose('li');
elementOpen('li');text(`Client budget and spend management, reporting and forecasting`);elementClose('li');
elementClose('ul');
elementClose('div');
  elementClose('div');
  return el;
};