
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('With increased scrutiny from the Regulator of Social Housing (RSH), it’s crucial that you maintain a robust understanding of your stock condition and the necessary steps to ensure compliance with the latest legislation. This includes the new Safety and Quality Standard, Decent Homes, and Housing Health and Safety Rating System (HHSRS).')
elementClose('p');
elementOpen('p');
text('Our typical stock condition survey consists of; component condition (including install date and date of replacement, coupled with detailed photos of each component), Decent Homes Standard (including Housing Health and Safety Rating System) and energy efficiency. Damp and mould assessments of each property, highlighting areas of concern and flagging extreme cases.')
elementClose('p');
elementOpen('p');
text('By collecting accurate survey data and formatting this data into the existing software package, you can make informed decisions on investment straight away.')
elementClose('p');
elementOpen('p');
text('Our trusted surveyors deliver detailed reports, providing clients with a physical reference point and content to help to form the basis of their future asset management and reinvestment strategies.')
elementClose('p');
elementOpen('p');
text('Our Stock Condition Surveys include:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Client asset management database review.`);elementClose('li');
elementOpen('li');text(`Survey configuration review and survey design set-up.`);elementClose('li');
elementOpen('li');text(`Existing stock condition data validation.`);elementClose('li');
elementOpen('li');text(`Decent Homes Standard (DHS) data collection and reporting.`);elementClose('li');
elementOpen('li');text(`All levels of energy data collection as well as SAP score generation.`);elementClose('li');
elementOpen('li');text(`Accurate stock condition data provision in a format compatible with any asset management system. Backed up by a PDF of each individual survey.`);elementClose('li');
elementOpen('li');text(`Regular client meetings to update progress and discuss delivery.`);elementClose('li');
elementClose('ul');
elementClose('div');
elementOpen('h2');text(`Damp and mould surveys`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Damp and mould are serious issues that can cause a range of problems if left untreated, the health impact can be detrimental and the infrastructure of a building can suffer. Therefore, it is crucial that housing providers are taking action and meeting relevant standards to always ensure the safety of their properties and their residents.  The introduction of Awaabs law in Feb 2023 now puts more responsibility on the Landlord identifying and treating the cause of any damp and mould occurrence within their stock.')
elementClose('p');
elementOpen('p');
text('Our team of highly qualified and experienced surveyors conduct damp and mould surveys through a non-invasive investigation of your property to identify whether your building poses a potential health risk if damp and mould are present according to the Homes (Fitness for Human Habitation) Act 2018. We will then specify the remedial action required to address the risk and improve safety.')
elementClose('p');
elementOpen('p');
text('Our extensive damp and mould survey includes the following:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Damp and mould survey of your entire property – including Hygrometer readings of affected surfaces, relative humidity and ‘dew point’ readings in every room, as well as thermal imaging of walls and surfaces`);elementClose('li');
elementOpen('li');text(`Detailed assessment of property design and construction (interior and exterior)`);elementClose('li');
elementOpen('li');text(`Cause and effect of the issues identified`);elementClose('li');
elementOpen('li');text(`Subsequent report on the existence of damp, mould and other issues, with actions, recommendations, and further guidance – through our network of trusted and experienced maintenance partners we can also provide quotes and frameworks for any recommended works`);elementClose('li');
elementOpen('li');text(`Building pathology assessment to diagnose any mechanisms of failure such as building defects, building decay and building performance`);elementClose('li');
elementOpen('li');text(`The survey is delivered in a detailed PDF report with all relevant readings, photographs and recommended remedial measures`);elementClose('li');
elementClose('ul');
elementClose('div');
elementOpen('h2');text(`Fire door Inspections`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('The fire safety regulations (2022) set out responsibilities for landlords:')
elementClose('p');
elementOpen('p');
text('For any multi-occupied residential building over 11m in height, responsibilities include:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`All fire doors in communal areas must be checked every 3 months`);elementClose('li');
elementOpen('li');text(`All flat entrance doors must be checked annually (by the Responsible Person)`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('Procom has a team of fully qualified Fire door inspectors who can carry out the standard 10 point check of all of your fire doors. We produce a detailed report for each individual door, highlighting any failure points, faults and listing recommended repairs in order to make the specific door compliant.')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};