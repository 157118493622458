import { JSXFactory } from '@appzuka/rxnano';
import { linkClick } from '@appzuka/rxnano';

export const QuantitySurveyor = (
  
<div class='job-desc'>
                <div class="job-title-wrapper">
                    <div class="job-row">
                        <h2>Job Title</h2>
                        <h2>Quantity Surveyor (Bill preparation expertise)</h2>
                    </div>
                    <div class="job-row">
                        <h2>Role Type</h2>
                        <h2>Permanant</h2>
                    </div>
                    <div class="job-row">
                        <h2>Job Location</h2>
                        <h2>Wokingham, Berkshire</h2>
                    </div>
                    <div class="job-row">
                        <h2>Reports to</h2>
                        <h2>Commercial Team lead</h2>
                    </div>
                </div>
                <h2>Requirement</h2>
                <p>Procom is progressing with a programme of significant growth combined with ongoing successful service delivery to our clients in the UK and abroad. As part of our growth programme, we require a Quantity Surveyor with a particular expertise in preparing bills of quantities to join our friendly, professional and highly successful team. You will need to have proven delivery experience, demonstrate that you work to a high standard. As Procom grows, you will have the opportunity to progress your career with us working across a wide range of sectors, projects and differing areas of professionalism that are covered within our service offering.</p>

                <h2>Role and Responsibilities</h2>
                <ul>

<li>Primarily responsible for preparation of Bills of Quantities on a wide range of project types and complexity to a high standard and in accordance with industry best practice, using recognised industry leading software (CostX, CAD measure, or similar)
</li><li>Promoting the Procom brand to both new and existing clients
</li><li>Ensuing development and maintenance of strong relationships with clients and other stakeholders through ensuring outstanding service delivery
</li><li>Carrying out duties efficiently and in accordance with business processes and procedures; 
</li><li>Providing input into development of business processes and procedures
</li><li>Taking personal responsibility for your compliance with quality, health, safety and environmental standards
</li><li>Primarily responsible for carrying out bill of quantities duties, and in addition undertaking quality assurance checks on outputs, programme management, preparation of cost estimates, value management, procurement and tendering, contract preparation and administration, valuations and change management, final account, issuing of certificates in accordance with contract requirements as required
</li><li>Preparation, delivery and presentation of professional reports to the clients, including project status reports, and providing professional advice, guidance and opinion
</li><li>Interfacing with the client, contractors and other consultants
</li><li>Cost planning and estimating as required, including preparation and presentation of the indicative cost budgets, elemental cost plans and also value management and engineering exercises
</li><li>Ensuring adherence to quality assurance processes and procedures in delivery of the commissions that you are responsible for
</li><li>Ensuring a high standard of accuracy by taking personal responsibility for making checks on own work quality, including written and calculated tasks
</li><li>Promoting commercial awareness
</li><li>Holding of all information received from client as confidential unless express permission has been given to divulge
</li><li>Acting in the client’s best interests by ensuring accurate and efficient delivery of the commissions that you are responsible for
</li><li>Leading and supporting junior members of the team as appropriate
  </li>
                </ul>
<h2>Knowledge skills & experience</h2>
<ul>

<li>A recognised Degree Qualification in Quantity Surveying or equivalent
</li><li>Associate or Chartered MRICS Quantity Surveyor, or working towards this
</li><li>Client focussed with ability to develop long term relationships
</li><li>Depth of experience in the profession, gained in a professional consultancy environment
</li><li>Strong technical grounding in the core areas of quantification and costing
</li><li>Experience in performing the role of Quantity Surveyor and acting as commission lead
</li><li>Good depth of knowledge of measurement standards including NRM2 and SMM7 
</li><li>Good depth of knowledge the main forms of contract, particularly JCT Design and Build, and their provisions around valuing variations/change, payment, programme, claims and notice requirements
</li><li>Ability to analyse, test and challenge data to ensure accuracy
</li><li>Confident and proficient in the use of MS Excel and MS Word
</li><li>Confident and proficient in the use of measurement and costing software (Cost X, CAD Measure, or similar)
</li>


</ul>
<h3>Person</h3>
<ul>

<li>Independent ‘self-starter’ and ability to work with minimal supervision;
</li><li>Enthusiastic and highly motivated;
</li><li>Experience in delivery of successful client focused service
</li><li>Demonstrates strong ethical conduct
</li><li>Demonstrates high standard of professional conduct
</li><li>Excellent communication and presentation skills
</li><li>Able to provide guidance and advice clearly and professionally
</li><li>Ability to work successfully within a team
</li><li>Excellent analytical skills
</li><li>Ability to solve problems
</li><li>Ability to perform under pressure
</li>
</ul>
<p>We are looking for an experienced Cost Consultant to be based on our head office in Wokingham, with the requirement to travel to project locations throughout the UK as necessary.</p>
<p>The role is offered on a full time and permanent basis. </p>
    <p>In return, Procom will offer the successful candidate a competitive salary and benefits commensurate with the role.</p>
<p>If this role excites you and you’re interested in applying, please send your current CV to: <a href="mailto:pcorfe@procom-uk.com" >pcorfe@procom-uk.com</a></p>
<p>Further details about Procom can be found on our website: <a href={`/about`} onclick={e => linkClick(e, `/about`)}>https://www.procom-uk.com/about</a></p>
            </div>


);
