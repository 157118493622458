import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

export const codePage =
  <>
    <h2>Code of Conduct</h2>
    <p>Procom takes an ethical approach to business. We are committed to honesty, fairness and professionalism in all of our dealings with our clients, supply chain, employees, other professional colleagues and the general public.</p>
    <p>This Code of Conduct is a firm and unwavering commitment by Procom to meet, and where possible exceed, the standards of professional conduct set out in this document.</p>
    <h2>Professional Conduct</h2>
    <p>We always strive to conduct ourselves in a professional and courteous manner. Procom aims to be a positive and respected ambassador for its clients, the industry in which we work and the professional institutions of which we are members.</p>
    <h2>Ethical Behaviour</h2>
    <p>Procom is committed to seeing that its staff act with integrity at all times and avoid conflicts of interest. The business actively encourages all members of the team to work in an open and transparent manner.</p>
    <p>Procom acts in the best interests of its clients. We aim to provide the right advice at the right time efficiently, so as to avoid any unnecessary expense for us or our clients.</p>
    <h2>Respect for our Supply Chain</h2>
    <p>At Procom, we are fortunate to be able to cater for most of our client’s needs using our internal team of experienced professionals. However, if we find we need to procure external resources, we will always treat them fairly, and encourage them to embrace the Procom collaborative and ethical approach to working.</p>
    <h2>Equality and Respect for Others</h2>
    <p>Procom believes that everyone deserves to be treated with courtesy, respect and without discrimination, irrespective of gender, race, disability, age, pregnancy, personal or religious beliefs, sexual orientation or marital status.</p>
    <h2>Fees</h2>
    <p>Procom is committed to forging lasting, mutually beneficial business relationships. With this in mind, we strive to keep our pricing competitive, so that our clients receive value for money for the services we deliver. We hope that if our clients can be sure that they are receiving best value, they will return to buy from us again in the future.</p>
    <p>We will try to present our fees and the scope of our services in a clear and unambiguous manner, so that there are no surprises for us or our clients.</p>
    <h2>Confidentiality</h2>
    <p>When you work with Procom, you can be assured of confidentiality and total discretion. We will, so far as reasonably practicable, keep you informed about how and when we would like to use your information or information about you.</p>
  </>

