
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('The procurement of construction and engineering work can be complex. Our complete procurement service is designed to provide our clients with the right procurement advice with careful consideration to their attitudes to the balance between risk, speed of delivery, quality and cost.')
elementClose('p');
elementOpen('p');
text('We have extensive knowledge and experience of EU procurement law, and are able to guide clients through the OJEU process where this applies to their planned construction and / or engineering work.')
elementClose('p');
elementOpen('p');
text('As well as giving advice, we are able to implement and manage the entire procurement process on behalf of our clients, from development of a robust procurement strategy through to tendering and on to the selection of preferred suppliers.')
elementClose('p');
elementOpen('p');
text('We are well versed in tailoring our procurement service to satisfy and co-ordinate with our clients’ internal procurement rules / standing orders.')
elementClose('p');
elementOpen('p');
text('Our procurement solutions include the following:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Procurement Strategy Advice & Implementation`);elementClose('li');
elementOpen('li');text(`Complete management of EOI, PQQ and ITT / ITQ Processes`);elementClose('li');
elementOpen('li');text(`Risk Assessment and Management`);elementClose('li');
elementClose('ul');
elementClose('div');
  elementClose('div');
  return el;
};