
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We have been working on target cost and cost reimbursable contracts for 14 years and have provided advice and support on more than 100 contracts to date, and have audited in excess of £2.5Bn of costs, helping our clients save more than £40m in cost to date.')
elementClose('p');
elementOpen('p');
text('We understand that clients need to have confidence when delivering their projects that they are:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Receiving value for money`);elementClose('li');
elementOpen('li');text(`Avoiding unnecessary costs`);elementClose('li');
elementOpen('li');text(`Not exposed to unpredictable cost increases`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('Procom can offer clients the expertise to bring confidence and transparency into the target cost and actual cost of contracts.')
elementClose('p');
elementOpen('p');
text('Our team have an in-depth understanding of the construction industry and the supply chain and use this combined with technical expertise and proven systems to identify costs which should not be charged to our clients.')
elementClose('p');
elementOpen('p');
text('We offer a full service from inception of contracts to completion and final account, including negotiating the outcome of our forensic audits on behalf of our clients.')
elementClose('p');
elementOpen('p');
text('A typical service offering could include:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`advice on procurement strategy`);elementClose('li');
elementOpen('li');text(`selection of form of contract`);elementClose('li');
elementOpen('li');text(`negotiation of terms and conditions`);elementClose('li');
elementOpen('li');text(`setting of the target costs`);elementClose('li');
elementOpen('li');text(`post contract administration of the target cost`);elementClose('li');
elementOpen('li');text(`interim and final actual cost audits`);elementClose('li');
elementOpen('li');text(`final account negotiations`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('We have demonstrable evidence that our involvement on projects pays for itself many times over, so our services are not an additional cost to our clients.')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};