import { PageContent } from '@appzuka/rxnano';

import { JSXFactory } from '@appzuka/rxnano';

import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';


import './brochureLinks.scss';

const brochureLink = (name, title, link) => {
  <render>
    <div class='brochure-link-outer'>
      <h2>{title}</h2>
      <a href={link} target='_blank' rel='nofollow noopener noreferrer'>
        <div class='brochure-link-wrapper'>
          {() => sitePix(SiteData.assetBase, name, { mediaSizes: '300px' })}
          <p>Click here to open document in pdf format.</p>
        </div>
      </a>
    </div>

  </render>
}

const brochures = () => {
  <render>
    <h2>Procom Brochures</h2>
    <p>Please click on the links below to download our brochures and learn more about Procom:</p>
    <div class='brochure-wrapper'>
      {() => brochureLink('thumbnails/2020profile', 'Procom Company Profile',
        'https://appzuka-web.s3.eu-west-2.amazonaws.com/procom/documents/Procom+Company+Profile.pdf')}

      {() => brochureLink('thumbnails/2021infrastructure', 'Procom Infrastructure Brochure',
        'https://appzuka-web.s3.eu-west-2.amazonaws.com/procom/documents/Procom+Infrastructure+Brochure.pdf')}
    </div>


  </render>

}

export {
  brochures
};

