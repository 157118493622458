
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Our extensive knowledge of construction and engineering contracts means that we can offer advice to our clients about the administering of contracts, or we can administer them entirely on their behalf.')
elementClose('p');
elementOpen('p');
text('Our expertise in this area offers our clients the following benefits:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Contract compliant discharge of our clients’ responsibilities under the contract`);elementClose('li');
elementOpen('li');text(`Seeing that the other party / parties to the contract perform properly as the contract stipulates; on time and to the requisite standards`);elementClose('li');
elementOpen('li');text(`Effective post contract commercial management to see that financial liabilities / exposure and entitlements are established in line with the provisions of the contract.`);elementClose('li');
elementOpen('li');text(`Prompt and effective resolution of non-compliance with the contract / contract breach utilising available remedies`);elementClose('li');
elementOpen('li');text(`Early identification and management of risks to the performance of the contract. The management of potential disputes to avoid impact to time, cost and quality.`);elementClose('li');
elementOpen('li');text(`Compliance with statutory requirements`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('Our contract administration solutions include:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Employer’s Agent / Contract Administrator roles`);elementClose('li');
elementOpen('li');text(`Contract administration advice service`);elementClose('li');
elementOpen('li');text(`Risk and Performance Management`);elementClose('li');
elementClose('ul');
elementClose('div');
  elementClose('div');
  return el;
};