
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Our cost planning service is designed to facilitate robust commercial planning and management of our clients’ capital and revenue projects.')
elementClose('p');
elementOpen('p');
text('Our focus is on cost certainty, risk mitigation and delivering best value. We also regularly assist clients in the development and management of their business cases, budgets / budgetary controls, and spend forecasting.')
elementClose('p');
elementOpen('p');
text('Our cost planning solutions include the following:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Feasibility Studies`);elementClose('li');
elementOpen('li');text(`Developing the Business Case`);elementClose('li');
elementOpen('li');text(`Detailed Cost Planning`);elementClose('li');
elementOpen('li');text(`Lifecycle and Whole Life Costing`);elementClose('li');
elementOpen('li');text(`Cash Flow Forecasting`);elementClose('li');
elementOpen('li');text(`Value Management and Value Engineering`);elementClose('li');
elementOpen('li');text(`Capital Allowances`);elementClose('li');
elementOpen('li');text(`Earned Value Analysis`);elementClose('li');
elementOpen('li');text(`Commercial Risk Assessment and Management`);elementClose('li');
elementClose('ul');
elementClose('div');
  elementClose('div');
  return el;
};