import { sitePix, linkClick, exportedSiteData } from '@appzuka/rxnano';

import './sitePreview.scss';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

const previewList = [
    {
        image: 'site/about',
        heading: 'About Us',
        content: 'Our philosophy is to build upon our areas of expertise, forming strong relationships with clients and providing the very best teams to provide surety of delivery.',
        link: '/about'
    },
    {
        image: 'site/projects',
        heading: 'Projects',
        content: 'We have worked across many industry sectors on projects ranging from £250k to £4 Billion in the UK. We are also currently involved in providing pre-contract services on a US$100 Billion project in the Middle East.',
        link: '/sectors'
    },
    {
        image: 'site/people',
        heading: 'Our People',
        content: 'The Procom team is comprised of varied professionals including: Quantity Surveyors, Cost Consultants, Project Managers, Forensic Cost Auditors & Principal Designers',
        link: '/about/people'
    }
];

const sitePreview = () => {
    <render>
        <div class='site-preview-wrapper'>
            {
                () => {
                    previewList.map(p => {
                        <render>
                            <a href={p.link} onclick={e => linkClick(e, p.link)} class='preview-block'>
                                { () => sitePix(exportedSiteData.assetBase, p.image, { filter: true }) }
                                <div class='preview-imagefilter'></div>
                                <div class='preview-content'>
                                    <h2>{p.heading}</h2>
                                    <p>{p.content}</p>
                                </div>
                                <div class='preview-side-content'>
                                    <h2>{p.heading}</h2>
                                    <p>{p.content}</p>
                                </div>
                            </a>
                        </render>
                    })
                }
            }
        </div>
    </render>

}

export {
    sitePreview
};

