
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Procom provide a full taking-off and Bill of Quantities preparation service for all types of projects. We utilise the latest software which allows complete data transparency and interrogation with outputs in Microsoft Excel or PDF.')
elementClose('p');
elementOpen('p');
text('We can take-off and provide Bills in a variety of formats, including SMM, NRM, CESMM etc or just Builders Quants. We specialise Bills for 2nd stage tenders.')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};