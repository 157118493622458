import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

export const valuesPage = (pco) => {
    <render>
        <h2>Our Values</h2>
        <h2>Co-operation</h2>
        <p>We always seek to work with and not against others. We believe that a collaborative and non-adversarial approach is at the heart of greater efficiency and successful outcomes.</p>
        <h2>Results Driven</h2>
        <p>We are focused and driven to achieve our clients desired outcomes. Successful results for our clients is success for Procom.</p>
        <h2>Innovative & Efficient</h2>
        <p>We always aim to work effectively to provide the right expertise at the right time. We work with tried and tested processes, but are not afraid to innovate to deliver better outcomes more efficiently.</p>
        <h2>Passion & Pride</h2>
        <p>We care about what we do and take pride in getting the right results for our clients.</p>
        <h2>Open, Honest & Fair</h2>
        <p>We believe in an ethical and fair approach to the way that we do business. Building trust is key to forging lasting relationships and the success of Procom and its clients.</p>
        <h2>Reliable</h2>
        <p>We are committed to doing what we say we are going to do and consistently providing a high quality service to our clients.</p>
        <h2>Respect & Communication</h2>
        <p>We believe that good communication and respect are the key to strong business relationships. We always listen carefully to our clients, so that we can best understand their needs.</p>
    </render>

}

