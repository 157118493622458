import { patch, JSXFactory, CustomElementHandler, render } from '@appzuka/rxnano';

import { pixLoadedObservable } from '@appzuka/rxnano';
// import { PageListItem } from 'src/basePages';

import { first } from 'rxjs/operators';

import { exportedSiteData } from '@appzuka/rxnano'

import './sectorBanner.scss';

import './serviceIndexBanner.scss';

interface ServiceLinkItem {
  label: string
  shortLabel?: string
  link?: string
}

type bannerFunction = (any) => void;

type bannerTitle = string | Node;

interface PageListItem {
  pageLocation: string
  content: any
  image: bannerFunction | string | [string, bannerTitle]
  pageTitle: string
  subMenu?: ServiceLinkItem[]
  name?: string
  index?: boolean
  hide?: boolean
}

const ServiceIndexBanner = (title) => {
  render(
    <>
      <div class="banner-image-wrapper">
        {() => renderImageBanner(title)}
        <div class="imagefilter"></div>
      </div>
      <div id="service-banner-title">
        <div id="service-banner-title__margin">
          {title}
        </div>
      </div>
    </>
  );
}

const renderImageBanner = (title) => {

  const serviceBannerPlaceholder = () => {
    const w = window.innerWidth;
    const h = w < 800 ? 0.5 : 0.4;
    const svginline = `data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 ${100 * h}"><rect width="680" height="${100 * h}"  style="fill:%23eee" />
        </svg>`;
    <render>
      <img src={svginline} />
    </render>
  }


  const multiPix = (ap4) => {

    const serviceTiles = ap4.filter(p => (p.file.indexOf('banners/services/tiles/servicetile') === 0));

    <render>
      <div id="service-banner-wrapper">
        {() => serviceTiles.sort((a, b) => a.file < b.file ? -1 : 0).map((t, i) => {
          const pixArr = ap4.filter(p => (p.file === t.file));
          if (pixArr.length > 0) {
            const pix = pixArr[0];
            const imageBase = `${exportedSiteData.assetBase}${pix.pathprefix}/${[pix.file, pix.variant, pix.hash].filter(p => p).join('-')}`
            const webpSrc = pix.sizes.map(s => `${imageBase}-${s}.webp ${s}w`).join();
            const jpgSrc = pix.sizes.map(s => `${imageBase}-${s}.jpg ${s}w`).join();
            const sizes = (pix.cssclass === 'wide-tile')
              ? "(max-width: 800px) 50vw, 40vw"
              : "(max-width: 800px) 25vw, 20vw";
            <render>
              <picture class={pix.cssclass}>
                <source type='image/webp' srcset={webpSrc} sizes={sizes} />
                <source type='image/jpeg' srcset={jpgSrc} sizes={sizes} />
                <img src={`${exportedSiteData.assetBase}${t.pathprefix}/${[t.file, t.variant, t.hash, 400].filter(x => x).join('-')}.jpg`} />
              </picture>
            </render>
          } else {
            console.log(`Service banner pix: ${t.file} not found`);
            { serviceBannerPlaceholder }
          }

        })}
      </div>
    </render>
    // }
  }

  <render>
    <div id='multi-aspect-pix'>
      {serviceBannerPlaceholder}
    </div>
  </render>

  // Replace the image once the pixlist is available
  pixLoadedObservable
    .pipe(
      first(a => a.length > 0)
    )
    .subscribe(ap => {
      const el2 = document.getElementById('multi-aspect-pix') as HTMLDivElement;
      patch(el2, () => multiPix(ap));
    })
}

export {
  ServiceIndexBanner
};
