import { sitePix, linkClick } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

import { newsData } from 'site/pages/about/newsdata';

import { newsPix } from 'site/pages/about/news';

import './newsSummary.scss';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

const previewList = [
    {
        image: 'site/about',
        heading: 'About Us',
        content: 'Our philosophy is to build upon our areas of expertise, forming strong relationships with clients and providing the very best teams to provide surety of delivery.',
        link: '/about'
    },
    {
        image: 'site/projects',
        heading: 'Projects',
        content: 'We have worked across many industry sectors on projects ranging from £250k to £4 Billion in the UK. We are also currently involved in providing pre-contract services on a US$100 Billion project in the Middle East.',
        link: '/sectors'
    },
    {
        image: 'site/people',
        heading: 'Our People',
        content: 'The Procom team is comprised of varied professionals including: Quantity Surveyors, Cost Consultants, Project Managers, Forensic Cost Auditors & Principal Designers',
        link: '/about/people'
    }
];


const newsHeadline1 = () => {
    <render>
        <div class='news-headline-wrapper'>
             { () => sitePix(SiteData.assetBase, 'site/1911-procom10') }
            <div class='news-headline-content'>
                <h2>Procom's 10th Birthday</h2>
                <p>We're extremely proud to have achieved this milestone as an established business that has developed and grown from being founded in 2009 by our Managing Director and Founder, Colin Hardiman. We're grateful to our valued clients for giving us the opportunities to support them on a variety of commissions over the past 10 years and we're looking forward to continuing to help them and new clients with their needs in future!</p>
            </div>

        </div>
    </render>
}

const newsHeadline2 = () => {
    <render>
        <div class='news-headline-wrapper'>
             { () => sitePix(SiteData.assetBase, 'site/grahamepark1') }
            <div class='news-headline-content'>
                <h2>Procom Team Help Community Garden Project</h2>
                <p>The Procom team were pleased to help Groundwork with a community garden project at Barnet Homes Ltd, Grahame Park estate.</p>
                <p>#help #community #socialvalue</p>
                <p>Click here for further details.</p>
            </div>
        </div>
    </render>
}

const newsHeadline3 = () => {
    <render>
        <div class='news-headline-wrapper'>
             { () => newsPix('site/2022top150') }
            <div class='news-headline-content'>
                <h2>Procom in Building Magazine's Top 150 Consultants in 2022</h2>
                <p>Procom Construction Consultants are really proud to have moved up to 98th position in the 2022 Building Magazine Top 150 Consultants, demonstrating great success by being in the top 100 in a field of strong competition! In addition, we are also delighted to have achieved 41st position in the Top 50 Quantity Surveyors list!</p>
                <p><a href={'/about/news'} onclick={e => linkClick(e, '/about/news')} class='news-link'>Click here for more...</a></p>
            </div>
        </div>
    </render>
}

//TODO: Increase error detection

const getHomeNews = () => {
    const homeItem = newsData.filter(n => n.homepage).filter(n => !n.homepage?.disable);
    if (homeItem.length < 1) {
        return undefined;
    } else {
        return homeItem[0];
    } 
}

const newsHeadline = () => {
    const homeItem = getHomeNews()?.homepage;
    if (homeItem) {
        <render>
            <div class='news-headline-wrapper'>
                { () => newsPix(homeItem.image) }
                <div class='news-headline-content'>
                    <div class='news-content' dangerouslySetInnerHTML={homeItem.summary}></div>
                    <p><a href={'/about/news'} onclick={e => linkClick(e, '/about/news')} class='news-link'>Click here for more...</a></p>
                </div>
            </div>
        </render>
    } else {
        <render>
            <p>Home News Item</p>
        </render>   
    }

}

const newsSummary = () => {
    <render>
        <a href={'/about/news'} onclick={e => linkClick(e, '/about/news')} class='news-link'>
        <div class='news-heading'>
            <h2>News & Media</h2>
            <h2>View All News & Media &gt;</h2>
        </div>
        <div class='news-summary-wrapper'>
            <div class='news-headline'>

                <div class="news-headline-separator"></div>
                { newsHeadline }
            </div>
            <div class='news-list'>
                <div class="news-list-separator"></div>
                <div class='news-items'>
                    {() => newsData.filter(n => n.title !== getHomeNews()?.title).slice(0,3).map(newsItem => {
                        <render>
                            <p>{newsItem.title}</p>
                            <div class='news-date'>
                                <p>{newsItem.date}</p>
                                <p>More...</p>
                            </div>
                            <div class="news-item-separator"></div>
                        </render>
                    })}
                </div>

             </div>
        </div>
        </a>
    </render>

}

export {
    newsSummary
};

