import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';
import { fromEvent } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

import './parallaxBanner.scss';

import { JSXFactory, render } from '@appzuka/rxnano';

const parallaxBanner = (pco, tagline) => {

  let bannerDiv: HTMLElement;
  let imageDiv: HTMLElement;

  const positionImage = (event) => {
    const rect = bannerDiv.getBoundingClientRect();
    const bTop = rect.top;
    const bBottom = rect.bottom;
    const brHeight = window.innerHeight;
    var actualInnerWidth = document.body.scrollWidth;
    const imHeight = actualInnerWidth * 3 / 4;
    const per = 1 - (bBottom / (brHeight + (bBottom - bTop)));
    const offset = per * (imHeight - (bBottom - bTop));
    imageDiv.style.top = `-${offset}px`;
  }

  fromEvent(window, 'scroll')
    .pipe(
      throttleTime(10, undefined, { trailing: true }),
      takeUntil(pco), // Cancel if the page is changed
    )
    .subscribe(positionImage);

  render(
    <div ref={e => bannerDiv = e} id='parallax-banner-1' class='parallax-banner'>
      <div ref={e => imageDiv = e} id='parallax-image-1' class='parallax-image'>
        {() => sitePix(SiteData.assetBase, 'parallax/shard', { mediaSizes: '100vw' })}
      </div>
      <div class='parallax-filter'></div>
      <div class='parallax-content'>
        <h2>{tagline}</h2>
      </div>
    </div>
  );

  // FIXME: Fix initial position if above fold on first load
  // Need to wait for items above to layout.
  setTimeout(() => positionImage(null), 500);
}

export {
  parallaxBanner
};

