
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('h2');text(`Services`);elementClose('h2');
elementOpen('ul');
elementOpen('li');text(`Significant experience across many industry sectors, including civil, residential, aviation, commercial, rail, education, leisure, defence, retail, utilities and infrastructure.`);elementClose('li');
elementOpen('li');text(`Both UK and international experience`);elementClose('li');
elementOpen('li');text(`Project values from £100k to £4Bn (and up to US$100Bn internationally)`);elementClose('li');
elementOpen('li');text(`Traditional and Design & Build procurement routes`);elementClose('li');
elementOpen('li');text(`Traditional and Partnering contracts`);elementClose('li');
elementOpen('li');text(`Contracts, Frameworks and PFI models`);elementClose('li');
elementOpen('li');text(`New build, refurbishment / renovation, regeneration, repairs and maintenance (planned and reactive)`);elementClose('li');
elementOpen('li');text(`Worked with many leading edge clients, such as BAA, Transport for London, Yorkshire Water, MoD and many of the world’s leading construction companies.`);elementClose('li');
elementClose('ul');
  elementClose('div');
  return el;
};