
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Our services include preliminary advice at a pre-contract stage to set out clear contract terms designed to mitigate the risk of disparity of intent between contracting parties, through to the management of negotiations when a dispute arises.')
elementClose('p');
elementOpen('p');
text('We also possess the expertise to undertake on our clients behalf, the complete management of disputes referred to Adjudication, Arbitration or legal proceedings.')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};